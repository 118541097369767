export class SalesStatistics {
  userEmail?: string;
  display_name?: string;
  odooId?: number;
  calendlyGenerated?: number;
  isMailSent?: boolean;
  signed_at?: string;
  id?: number;
  salesperson?: number;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }
    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.userEmail !== undefined) {
      this.userEmail = item.userEmail;
    }
    if (item.display_name !== undefined) {
      this.display_name = item.display_name;
    }
    if (item.odooId !== undefined) {
      this.odooId = item.odooId;
    }
    if (item.calendlyGenerated !== undefined) {
      this.calendlyGenerated = item.calendlyGenerated;
    }
    if (item.isMailSent !== undefined) {
      this.isMailSent = item.isMailSent;
    }
    if (item.signed_at !== undefined) {
      this.signed_at = item.signed_at;
    }
    if (item.salesperson !== undefined) {
      this.salesperson = item.salesperson;
    }
  }
}
