export class UserBranch {
  id?: number;
  userId: number;
  branches?: string;
  createdAt?: string;
  updatedAt?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.userId !== undefined) {
      this.userId = item.userId;
    }
    if (item.branches !== undefined) {
      this.branches = item.branches;
    }
  }
}
