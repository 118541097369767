import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import Bugsnag from '@bugsnag/js';

@Injectable()
export class CustomerGuard {

    constructor(private _auth: AuthService) {
    }

    OnDestroy() {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise(async (resolve, reject) => {
            try {
                let trace_id: string;
                const currentUserId = localStorage.getItem("user_id");
                const odooCustomerId = parseInt(route.params?.odooCustomerId, 10);
                const currentDate = moment().format('YYYYMMDD');
                if (currentUserId && odooCustomerId && currentDate) {
                    trace_id = `${odooCustomerId}_${currentUserId}_${currentDate}`;
                    localStorage.setItem("trace_id", trace_id);
                    return resolve(true);
                } else {
                    throw new Error(`Error in creating trace id. { trace_id: ${trace_id} }`);
                }
            } catch (error) {
                console.log('Customer Guard Error', error);
                Bugsnag.notify(error);
                resolve(true);
            }
        });
    }
}
