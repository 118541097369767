export class PaymentLog {
  id?: number;
  guid?: string;
  odooId?: number;
  paymentApiRequest?: string;
  paymentResult?: string;
  tokenId?: string;
  status?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.odooId !== undefined) {
      this.odooId = item.odooId;
    }
    if (item.paymentApiRequest !== undefined) {
      this.paymentApiRequest = item.paymentApiRequest;
    }
    if (item.paymentResult !== undefined) {
      this.paymentResult = item.paymentResult;
    }
    if (item.tokenId !== undefined) {
      this.tokenId = item.tokenId;
    }

    if (item.status !== undefined) {
      this.status = item.status;
    }
  }
}
