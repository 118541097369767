export const userConfig = {
  permission: {
    user: 118,
    manager: 119,
    admin: 120,
  },
  storage: {
    auth: {
      USERNAME: 'HPT_USERNAME',
      PASSWORD: 'HPT_PASSWORD',
      TOKEN: 'HPT_BACKEND_TOKEN',
    },
  },
};
