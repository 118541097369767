import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Association } from 'app/shared/models/association.model';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class AssociationService  {
  onAssociationChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;

  private _association: any;

  constructor(private _http: HttpClient) {
    // Set the defaults
    this._association = [];
    this.onAssociationChanged = new BehaviorSubject(this._association);
    this.onSearchTextChanged = new Subject();
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      const association: any = [];
      Promise.all([this.listAssociations()]).then((result) => {
        this.onSearchTextChanged.subscribe((searchText) => {
          const filteredAssociations = FuseUtils.filterArrayByString(
            this._association.configuration,
            searchText,
          );
          this._association = filteredAssociations.map((item) => {
            return {
              id: item.id,
              configuration: JSON.parse(item.configuration),
            };
            // return new Association(item);
          });
          // association = filteredAssociations.map((item) => {
          //   return {
          //     id: item.id,
          //     configuration: JSON.parse(item.configuration),
          //   };
          // });
          this.onAssociationChanged.next(this._association);
        });
        resolve(this._association);
      }, reject);
    });
  }
  /* ********************* searchAssociation start ************/
  getSearchAssociations(searchText: string) {
    const filteredAssociations = FuseUtils.filterArrayByString(this._association, searchText);
    // if (filteredAssociations && filteredAssociations.length > 0) {
    //   console.log(filteredAssociations);
    //   filteredAssociations = filteredAssociations.map((item) => {
    //     return {
    //       id: item.id,
    //       configuration: JSON.parse(item.configuration),
    //     };
    //     // return new Association(item);
    //   });
    // }
    return filteredAssociations;
  }
  /* ********************* searchAssociation End ************/

  // Add New Association Info
  addNewAssociation(newAssociation: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this._http
        .post(`/api/salespro/associations`, newAssociation)
        .subscribe((response: any) => {
          const associationObj = {
            id: response.id,
            configuration: JSON.parse(response.configuration),
          };
          this._association.push(associationObj);
          this.onAssociationChanged.next(this._association);
          resolve(response);
        }, reject);
    });
  }

  // Get All Association Info
  listAssociations(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/associations`).subscribe((response: any) => {
        this._association = response;

        this._association = this._association.map((item) => {
          return {
            id: item.id,
            configuration: JSON.parse(item.configuration),
          };
          // return new Association(item);
        });

        this.onAssociationChanged.next(this._association);
        resolve(this._association);
      }, reject);
    });
  }

  // Get Association Info by id
  retrieveAssociation(associationName: any): Promise<Association> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/associations`).subscribe((response: any) => {
        console.log(response);
        this._association = response;
        console.log('uuuu');
        console.log(associationName);
        const associations = [];
        this._association = this._association.find(
          (item) => JSON.parse(item.configuration).name === associationName,
        );
        if (this._association) {
          this._association.configuration = JSON.parse(this._association.configuration);
        }
        // this._association.push(associationObj);
        // let _association = this._association.find((item) => {

        // return {
        //   id: item.id,
        //   configuration: JSON.parse(item.configuration),
        // };
        // }

        //
        // });

        // this.onAssociationChanged.next(association);
        resolve(this._association);
      }, reject);
    });
  }

  // Remove the Selected Association Info
  removeAssociation(selectedAssociation: Association): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .delete(`/api/salespro/associations/${selectedAssociation.id}`)
        .subscribe((response: any) => {
          const AssociationIndex = this._association.indexOf(selectedAssociation);
          this._association.splice(AssociationIndex, 1);
          this.onAssociationChanged.next(this._association);
          resolve(response);
        }, reject);
    });
  }

  // Update the Selected Association Info
  updateAssociation(oldAssociation: Association, newAssociation: any): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(oldAssociation, newAssociation);
      // let association = JSON.stringify(newAssociation)
      this._http
        .patch(`/api/salespro/Associations/${oldAssociation.id}`, newAssociation)
        .subscribe((response: any) => {
          const AssociationIndex = this._association.indexOf(oldAssociation);
          /*if (oldAssociation && oldAssociation.update) {
            oldAssociation.update(newAssociation);
          }*/
          const associationConfig = {
            id: oldAssociation.id,
            configuration: JSON.parse(newAssociation.configuration),
          };
          this._association[AssociationIndex] = associationConfig;
          this.onAssociationChanged.next(this._association);
          resolve(response);
        }, reject);
    });
  }
}
