// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folded:not(.unfolded) :host .nav-link > .nav-link-title,
.folded:not(.unfolded) :host .nav-link > .nav-link-badge {
  opacity: 0;
  transition: opacity 200ms ease;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIml0ZW0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR007O0VBRUUsVUFBQTtFQUNBLDhCQUFBO0FBRlIiLCJmaWxlIjoiaXRlbS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgLmZvbGRlZDpub3QoLnVuZm9sZGVkKSAmIHtcbiAgICAubmF2LWxpbmsge1xuICAgICAgPiAubmF2LWxpbmstdGl0bGUsXG4gICAgICA+IC5uYXYtbGluay1iYWRnZSB7XG4gICAgICAgIG9wYWNpdHk6IDA7XG4gICAgICAgIHRyYW5zaXRpb246IG9wYWNpdHkgMjAwbXMgZWFzZTtcbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/vertical/item/item.component.scss"],"names":[],"mappings":"AAGM;;EAEE,UAAA;EACA,8BAAA;AAFR;AACA,wgBAAwgB","sourcesContent":[":host {\n  .folded:not(.unfolded) & {\n    .nav-link {\n      > .nav-link-title,\n      > .nav-link-badge {\n        opacity: 0;\n        transition: opacity 200ms ease;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
