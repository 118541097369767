import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OdooRpcService } from '@cogito/angular-odoo';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class UserService  {
  onSearchTextChanged: Subject<any>;
  onUserChanged: BehaviorSubject<any>;

  private _users: any[];

  constructor(
    private _odoo: OdooRpcService
  ) {
    // Set the defaults
    this._users = [];
    this.onSearchTextChanged = new Subject();
    this.onUserChanged = new BehaviorSubject(this._users);
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllUsers()
      ]).then((users) => {
        this.onSearchTextChanged.subscribe(searchText => {
          const filteredUsers = FuseUtils.filterArrayByString(this._users, searchText);
          this.onUserChanged.next(filteredUsers);
        });
        resolve(users);
      }, reject);
    });
  }

  // Get All User Information
  getAllUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._odoo.call('res.users', 'search_read', [
        [
          '|',
          '|',
          [ 'groups_id', '=', 118 ],
          [ 'groups_id', '=', 119 ],
          [ 'groups_id', '=', 120 ]
        ]
      ], {})
      .subscribe((response: any) => {
        this._users = response;
        this._users = this._users.map(item => {
          return {
            id: item.id,
            display_name: item.display_name,
            name: item.name,
            phone: item.phone,
            email: item.email,
            sel_groups_118_119_120: item.sel_groups_118_119_120
          };
        });

        this.onUserChanged.next(this._users);
        resolve(this._users);
      }, reject);
    });
  }

  // Get the User by UID
  getUserByID(uid: string): Promise<any> {
    return this._odoo.call('res.users', 'search_read', [
      [
        [ 'id', '=', uid ]
      ]
    ], {}).toPromise();
  }

  // Add new User Information
  addNewUser(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let userName = user.firstName;
      if (userName) {
        if (user.lastName) {
          userName += ' ' + user.lastName;
        }
      } else {
        userName = user.lastName;
      }
      if (!user.phone) {
        user.phone = false;
      }
      this._odoo.call('res.users', 'create', [{
        active: true,
        name: userName,
        phone: user.phone,
        email: user.email,
        login: user.email,
        lang: 'en_US',
        notify_email: 'always',
        tz: 'US/Central',
        sel_groups_118_119_120: Number(user.permission)
      }], {}).subscribe(async (newUserId: any) => {
        try {
          const newUser = await this._odoo.call('res.users', 'search_read', [
            [
              [ 'id', '=', newUserId ]
            ]
          ], {
            limit: 1
          }).toPromise();
          console.log('New User', newUser);

          this._users.push(newUser[0]);
          this.onUserChanged.next(this._users);
          resolve(newUser[0]);
        } catch (error) {
          reject(error);
        }
      }, reject);
    });
  }

  // Remove the Selected User Info
  removeUser(selectedUser: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._odoo.call('res.users', 'unlink', [[ selectedUser.id ]]).subscribe((response: any) => {
        const userIndex = this._users.indexOf(selectedUser);
        this._users.splice(userIndex, 1);
        this.onUserChanged.next(this._users);
        resolve(response);
      }, reject);
    });
  }

  // Update the Selected User Info
  updateUser(oldUser: any, user: any) {
    return new Promise((resolve, reject) => {
      let userName = user.firstName;
      if (userName) {
        if (user.lastName) {
          userName += ' ' + user.lastName;
        }
      } else {
        userName = user.lastName;
      }
      if (!user.phone) {
        user.phone = false;
      }
      this._odoo.call('res.users', 'write', [ [ oldUser.id ], {
        name: userName,
        phone: user.phone,
        email: user.email,
        sel_groups_118_119_120: Number(user.permission)
      }], {}).subscribe(async (updated: any) => {
        try {
          const userIndex = this._users.indexOf(oldUser);
          oldUser.display_name = userName;
          oldUser.phone = user.phone;
          oldUser.email = user.email;
          oldUser.sel_groups_118_119_120 = Number(user.permission);
          this._users[userIndex] = oldUser;
          this.onUserChanged.next(this._users);
          resolve(updated); 
        } catch (error) {
          reject(error);
        }
      }, reject);
    });
  }
}
