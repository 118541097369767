export const aciConfig = {
  NachaStandardEntryClass: {
    ACK: 'ACK',
    ADV: 'ADV',
    ATX: 'ATX',
    CBR: 'CBR',
    CCD: 'CCD',
    CIE: 'CIE',
    COR: 'COR',
    CTX: 'CTX',
    DNE: 'DNE',
    ENR: 'ENR',
    MTE: 'MTE',
    PBR: 'PBR',
    POP: 'POP',
    POS: 'POS',
    PPD: 'PPD',
    RCK: 'RCK',
    RET: 'RET',
    SHR: 'SHR',
    TEL: 'TEL',
    TRC: 'TRC',
    TRX: 'TRX',
    WEB: 'WEB',
    XCK: 'XCK',
  }
};
