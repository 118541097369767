import { Injectable } from '@angular/core';
import { CustomerStar } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerStarService {
  constructor(private _http: HttpClient) {}

  addNewCustomerStar(newCustomerStar: CustomerStar): Promise<any> {
    return this._http.post(`/api/salespro/customer-stars`, newCustomerStar).toPromise();
  }

  getAllCustomerStars(): Promise<CustomerStar[]> {
    return this._http
      .get(`/api/salespro/customer-stars`)
      .pipe(
        map((result) => result as object[]),
        map((result) => result.map((item) => new CustomerStar(item))),
      )
      .toPromise();
  }

  getAllCustomerStarsByLeadIds(userOdooIdArray): Promise<CustomerStar[]> {
    return this._http
      .post(`/api/salespro/customer-stars/getAllCustomerStarsByLeadIds`, userOdooIdArray)
      .pipe(
        map((result) => result as object[]),
        map((result) => result.map((item) => new CustomerStar(item))),
      )
      .toPromise();
  }

  removeCustomerStar(customerStar: CustomerStar) {
    return this._http.delete(`/api/salespro/customer-stars/${customerStar.odooId}`).toPromise();
  }

  getAllCustomerStarsByLimit(limit, offset): Promise<any> {
    return (
      this._http
        .post(`/api/salespro/customer-stars/getAllCustomerStarsByLimit`, { limit, offset })
        /*.pipe(
        map((result) => result as object[]),
        map((result) => result.map((item) => new CustomerStar(item))),
      )*/
        .toPromise()
    );
  }
}
