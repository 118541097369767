export const customerConfig = {
  leadTypeShowroom: [{ 'name': 'Show Room','value': 'Show Room'}],
  leadType: [{ 'name': 'Trimmed', 'value': 'Trimmed'}, { 'name': 'Self Gen','value': 'Self Gen'}],
  leadTypeRenewal: [{ 'name': 'Renewal', 'value': 'Renewal'}],
  homeProMaxLoanAmt60Month: 2000,
  homeProMaxLoanAmt36Month: 1200,
  leadTypePhoneSales: [{'name': 'Phone Sales', 'value': 'Phone Sales'}],
  leadTypeOnitHome: [{'name': 'OnIt Home', 'value': 'Onit Home'}],

  rmrEditPackage: ['Legacy Customer'],
  promotionalPercentage: 50 / 100,
};
