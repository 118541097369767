import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'fuse-theme-options',
  templateUrl: './theme-options.component.html',
  styleUrls: [
    './theme-options.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FuseThemeOptionsComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  form: UntypedFormGroup;

  @HostBinding('class.bar-closed')
  barClosed: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FormBuilder} _formBuilder
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _formBuilder: UntypedFormBuilder,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService
  ) {
    // Set the defaults
    this.barClosed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Build the config form
    // noinspection TypeScriptValidateTypes
    this.form = this._formBuilder.group({
      colorTheme: new UntypedFormControl()
    });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        // Update the stored config
        this.fuseConfig = config;

        // Set the config form values without emitting an event
        // so that we don't end up with an infinite loop
        const { colorTheme } = config;
        this.form.setValue({
          colorTheme
        }, { emitEvent: false });
      });

    // Subscribe to the form value changes
    this.form.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        // Update the config
        this._fuseConfigService.config = config;
      });

    // Add customize nav item that opens the bar programmatically
    const customFunctionNavItem = {
      id: 'custom-function',
      title: 'Custom Function',
      type: 'group',
      icon: 'settings',
      children: [
        {
          id: 'customize',
          title: 'Customize',
          type: 'item',
          icon: 'settings',
          function: () => {
            this.toggleSidebarOpen('themeOptionsPanel');
          }
        }
      ]
    };

    this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Remove the custom function menu
    this._fuseNavigationService.removeNavigationItem('custom-function');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
