import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map, catchError } from 'rxjs/operators';
import { OdooRpcService, ɵa } from '@cogito/angular-odoo';
import { AuthService } from 'app/core/auth/auth.service';
import { LogsService } from 'app/core/services/logs.service';
import { Customer, Log } from 'app/shared/models';
import { crmTeamConfig, crmTagConfig } from 'app/configs';
import { CustomerNewService } from 'app/core/services/customer-new.service';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SelfgenService {
  constructor(
    private _http: HttpClient,
    private _odoo: OdooRpcService,
    private _odooCokkies: ɵa,
    private _authService: AuthService,
    private _logsService: LogsService,
    private _customerService: CustomerNewService,
  ) {}

  /* ******************************** SELF GEN ****************************/

  getNewAddressSearch(searchText: string) {
    return new Promise((resolve, reject) => {
      const db = environment.odoo.DATABASE;
      const sUrl = `/api/salesproVOne/address/search/${db}?address=${searchText}`;
      const sessionId = this._odooCokkies.getSessionId();

      this._http
        .get(sUrl, {
          headers: new HttpHeaders({
            'X-Openerp': sessionId,
          }),
        })
        .subscribe((addressResult: any) => {
          let customers: any = [];
          const aCount = parseInt(addressResult.count, 10);
          if (addressResult && aCount > 0) {
            const limit = aCount > 100 ? 100 : aCount;
            const slicedAddress = addressResult.details.slice(0, limit);
            customers = slicedAddress.map((item) => {
              return {
                leadId: item.leads && item.leads.length > 0 ? item.leads[0].lead_id : null,
                leadData: item.leads && item.leads.length > 0 ? item.leads[0] : [],
                displayName: item.formatted_address,
                siteId: item.site && item.site.site_id ? item.site.site_id : null,
                ownerId: item.owner && item.owner.owner_id ? item.owner.owner_id : null,
                city: item.city ? item.city : null,
                street: item.street ? item.street : null,
                stateName: item.state_name ? item.state_name : null,
                zip: item.zip ? item.zip : null,
                owner: item.owner ? item.owner : null,
                type: 'odoo',
                state_code: item.state_code ? item.state_code : null,
                site: item.site ? item.site : null,
              };
            });

            /* customers.push({
              leadId: 0,
              displayName: 'Fetch More Results',
              state_code: null,
            });*/
            resolve(customers);
          } else {
            customers.push({
              leadId: 0,
              displayName: 'Fetch More Results',
            });
            resolve(customers);
          }
        }, reject);
    });
  }
  getCustomerPreviousGoogleAddress(address: string) {
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/address/verify/${db}?address=${address}`;
    const sessionId = this._odooCokkies.getSessionId();
    return new Promise((resolve, reject) => {
      this._http
        .get(sUrl, {
          headers: new HttpHeaders({
            'X-Openerp': sessionId,
          }),
        })
        .subscribe((addressResult: any) => {
          console.log(addressResult);
          let googlePlaces: any = [];
          let googleAddressCount = 0;
          let slicedAddress = [];
          if (addressResult && addressResult.ext_details && addressResult.ext_details.length > 0) {
            if (
              addressResult.ext_details[0].result &&
              addressResult.ext_details[0].result === 'NOT OK'
            ) {
              googleAddressCount = 0;
            } else {
              googleAddressCount = addressResult.ext_details.length;
            }
          }
          if (googleAddressCount > 0) {
            const limit = googleAddressCount > 15 ? 15 : googleAddressCount;
            slicedAddress = addressResult.ext_details.slice(0, limit);
            googlePlaces = slicedAddress.map((item) => {
              return {
                status: 'Success',
                displayName: item && item.formatted_address ? item.formatted_address : null,
                streetName: item && item.street_name ? item.street_name : null,
                city: item && item.city ? item.city : null,
                state: item && item.state_name ? item.state_name : null,
                zipCode: item && item.zip_code ? item.zip_code : null,
                state_code: item && item.state_code ? item.state_code : null,
              };
            });
          } else {
            googlePlaces.push({
              status: 'Failure',
              displayName: 'Address Not Available',
            });
          }
          resolve(googlePlaces);
        }, reject);
    });
  }
  getGoogleAddress(searchText: string) {
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/address/verify/${db}?address=${searchText}`;

    const sessionId = this._odooCokkies.getSessionId();
    console.log('===sessionId==', sessionId);

    return new Promise((resolve, reject) => {
      this._http
        .get(sUrl, {
          headers: new HttpHeaders({
            'X-Openerp': sessionId,
          }),
        })
        .subscribe((addressResult: any) => {
          let googlePlaces: any = [];
          let aGoogleCount = 0;
          if (addressResult && addressResult.ext_details && addressResult.ext_details.length > 0) {
            if (
              addressResult.ext_details[0].result &&
              addressResult.ext_details[0].result === 'NOT OK'
            ) {
              aGoogleCount = 0;
            } else {
              aGoogleCount = addressResult.ext_details.length;
            }
          }

          const aDetialsCount =
            addressResult && addressResult.details && addressResult.details.length > 0
              ? addressResult.details.length
              : 0;
          let isArrayType = 'new';
          let slicedAddress = [];
          if (aDetialsCount > 0) {
            isArrayType = 'odoo';
            const limit = aDetialsCount > 15 ? 15 : aDetialsCount;
            slicedAddress = addressResult.details.slice(0, limit);
          } else if (aGoogleCount > 0) {
            const limit = aGoogleCount > 15 ? 15 : aGoogleCount;
            slicedAddress = addressResult.ext_details.slice(0, limit);
            isArrayType = 'google';
          }

          if (aDetialsCount > 0 || aGoogleCount > 0) {
            googlePlaces = slicedAddress.map((item) => {
              return {
                leadId:
                  isArrayType === 'odoo' && item.leads && item.leads.length > 0
                    ? item.leads[0].lead_id
                    : null,
                leadData:
                  isArrayType === 'odoo' && item.leads && item.leads.length > 0
                    ? item.leads[0]
                    : [],
                displayName: item.formatted_address,
                siteId:
                  isArrayType === 'odoo' && item.site && item.site.site_id
                    ? item.site.site_id
                    : null,
                ownerId:
                  isArrayType === 'odoo' && item.owner && item.owner.owner_id
                    ? item.owner.owner_id
                    : null,
                city: item.city ? item.city : null,
                street:
                  isArrayType === 'odoo' && item.street
                    ? item.street
                    : isArrayType === 'google' && item.street_name
                    ? item.street_name
                    : null,
                stateName: item.state_name ? item.state_name : null,
                zip:
                  isArrayType === 'odoo' && item.zip
                    ? item.zip
                    : isArrayType === 'google' && item.zip_code
                    ? item.zip_code
                    : null,
                owner: isArrayType === 'odoo' && item.owner ? item.owner : null,
                type: isArrayType,
                state_code: item.state_code ? item.state_code : null,
                site: isArrayType === 'odoo' && item.site ? item.site : null,
              };
            });

            if (isArrayType === 'google') {
              googlePlaces.push({
                displayName: 'Address Not Available',
                street: null,
                city: null,
                stateName: null,
                siteId: null,
                ownerId: null,
                type: 'new',
                state_code: null,
                site: null,
              });
            }
          } else {
            googlePlaces.push({
              displayName: 'Address Not Available',
              street: null,
              city: null,
              stateName: null,
              siteId: null,
              ownerId: null,
              type: 'new',
              state_code: null,
              site: null,
            });
          }
          resolve(googlePlaces);
        }, reject);
    });
  }

  searchCustomer(newCustomer) {
    return new Promise((resolve, reject) => {
      const currentUser = this._authService.currentAuthValue;

      const db = environment.odoo.DATABASE;
      const sUrl = `/api/salesproVOne/cust/search/${db}`;
      const sessionId = this._odooCokkies.getSessionId();
      const customerData = {
        customer_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
        customer_email: newCustomer.email,
        customer_phone: newCustomer.phone,
        customer_mobile: '',
        uid: currentUser.uid,
        channel: 'SPRO',
      };
      return this._http
        .post(sUrl, customerData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Openerp': sessionId,
          }),
        })
        .subscribe((searchResult: any) => {
          resolve(searchResult);
        }, reject);
    });
  }

  async createCustomer(newCustomer, siteId) {
    return new Promise((resolve, reject) => {
      const currentUser = this._authService.currentAuthValue;
      const db = environment.odoo.DATABASE;
      const sUrl = `/api/salesproVOne/cust/${db}`;
      const sessionId = this._odooCokkies.getSessionId();
      const customerData = {
        customer_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
        customer_email: newCustomer.email,
        secondary_email: '',
        customer_phone: newCustomer.phone,
        customer_mobile: newCustomer.mobile ? newCustomer.mobile: '',
        site_id: siteId,
        uid: currentUser.uid,
        channel: 'SPRO',
        site_owner_id: '',
      };
      return this._http
        .post(sUrl, customerData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Openerp': sessionId,
          }),
        })
        .subscribe(async (customerObj: any) => {
          const logObj = new Log({
            input_params: JSON.stringify(customerData),
            output_response: JSON.stringify(customerObj),
            page_type: 'add',
            // street: `${newCustomer.streetNumber} ${newCustomer.streetName} ${newCustomer.streetType}`,
            street: `${newCustomer.streetName}`,
            description: 'Customer Creation',
          });

          const logApiResponse = await this._logsService.logDetails(logObj);
          resolve(customerObj);
        }, reject);
    });
  }

  createSite(newCustomer) {
    return new Promise((resolve, reject) => {
      const currentUser = this._authService.currentAuthValue;
      const siteData = {
        // street: `${newCustomer.streetNumber} ${newCustomer.streetName} ${newCustomer.streetType}`,
        street: `${newCustomer.streetName}`,
        street2: '',
        city: newCustomer.city,
        channel: 'SPRO',
        state_code: newCustomer.state_code,
        zip_code: newCustomer.zipCode,
        uid: currentUser.uid,
        builder_id: null,
        community_id: null,
        branch: 'DFW',

        is_ext_verified: newCustomer.is_ext_verified,
        projected_close_date: '',
      };
      const db = environment.odoo.DATABASE;
      const sUrl = `/api/salesproVOne/site/${db}`;
      const sessionId = this._odooCokkies.getSessionId();

      return this._http
        .post(sUrl, siteData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Openerp': sessionId,
          }),
        })
        .subscribe(async (siteObj: any) => {
          const logObj = new Log({
            input_params: JSON.stringify(siteData),
            output_response: JSON.stringify(siteObj),
            page_type: 'add',
            // street: `${newCustomer.streetNumber} ${newCustomer.streetName} ${newCustomer.streetType}`,
            street: `${newCustomer.streetName}`,
            description: 'Site Creation',
          });

          const logApiResponse = await this._logsService.logDetails(logObj);
          resolve(siteObj);
        }, reject);
    });
  }

  async createLead(newCustomer, siteId, ownerId) {
    return new Promise(async (resolve, reject) => {
      const currentUser = this._authService.currentAuthValue;
      const db = environment.odoo.DATABASE;
      const sUrl = `/api/salesproVOne/lead/${db}`;
      const sessionId = this._odooCokkies.getSessionId();

      const leadData = {
        site_id: siteId,
        uid: currentUser.uid,
        channel: 'SPRO',
        owner_id: ownerId,
        opportunity_type: 'Post-close Security',
        contract_type: 'Field Sales',
        is_preselect: false,
      };
      return this._http
        .post(sUrl, leadData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Openerp': sessionId,
          }),
        })
        .subscribe(async (leadObj: any) => {
          const logObj = new Log({
            input_params: JSON.stringify(leadData),
            output_response: JSON.stringify(leadObj),
            page_type: 'add',
            // street: `${newCustomer.streetNumber} ${newCustomer.streetName} ${newCustomer.streetType}`,
            street: `${newCustomer.streetName}`,
            description: 'Lead Creation',
          });

          const logApiResponse = await this._logsService.logDetails(logObj);
          resolve(leadObj);
        }, reject);
    });
  }

  async createLeadWithSiteCust(leadData, newCustomer) {
    return new Promise(async (resolve, reject) => {
      const currentUser = this._authService.currentAuthValue;
      const db = environment.odoo.DATABASE;
      const sUrl = `/api/salesproVOne/lead/${db}`;
      const sessionId = this._odooCokkies.getSessionId();

      return this._http
        .post(sUrl, leadData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Openerp': sessionId,
          }),
        })
        .subscribe(async (leadObj: any) => {
          const logObj = new Log({
            input_params: JSON.stringify(leadData),
            output_response: JSON.stringify(leadObj),
            page_type: 'add',
            // street: `${newCustomer.streetNumber} ${newCustomer.streetName} ${newCustomer.streetType}`,
            street: `${newCustomer.streetName}`,
            description: 'Lead Creation',
          });

          const logApiResponse = await this._logsService.logDetails(logObj);
          const currentLeadResult = leadObj && leadObj.result ? JSON.parse(leadObj.result) : null;
          if(currentLeadResult){
            await this._customerService.addInternalNote(
              currentLeadResult.odoo_lead_id,
              'Stage Changed' +
              '<ul>' +
              '<li>' +
              '    Stage:' +
              '        <span> ' +
              crmTagConfig.status.CUSTOMER_INFO_ADDED +
              '</li>' +
              '</ul>',
            )
          }
          resolve(leadObj);
        }, reject);
    });
  }
}
