export class Permissions {
    id?: number;
    policy_type?: string;
    v0?: string;
    userId?:number;
    group_name?:string;
    group_id?:string;
    description?: [];
    action?: string;
    user_roles?:[];
    createdAt?: string;
    updatedAt?: string;
    // Constructor
    constructor(item) {
      this.update(item);
    }
  
    // Update the model
    update(item) {
      if (!item) {
        return;  
      }
  
      if (item.id !== undefined) {
        this.id = item.id ;
      }
      if (item.policy_type !== undefined) {
        this.policy_type = item.policy_type;
      }
      if (item.v0 !== undefined) {
        this.v0 = item.v0;
      }
      if (item.userId !== undefined) {
        this.userId = item.userId ;
      }
     if(item.group_name !== undefined){
        this.group_name = item.group_name;
     }

     if(item.group_id !== undefined){
      this.group_id = item.group_id;
   }
      if (item.description !== undefined) {
        this.description = item.description;
      }
      if (item.action!== undefined) {
        this.action= item.action ;
      }
      if (item.user_roles!== undefined) {
        this.user_roles= item.user_roles ;
      }
      if (item.createdAt !== undefined) {
        this.createdAt = item.createdAt;
      }
      if (item.updatedAt !== undefined) {
        this.updatedAt = item.updatedAt;
      }
     
    }
  }
  