import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Video } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VideoService  {
  onVideoChanged: BehaviorSubject<any>;

  private _videos: Video[];

  constructor(
    private _http: HttpClient
  ) {
    // Set the defaults
    this._videos = [];
    this.onVideoChanged = new BehaviorSubject(this._videos);
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllVideos()
      ]).then((result) => {
        resolve(this._videos);
      }, reject);
    });
  }

  // Get All Video Info
  getAllVideos(): Promise<Video[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/videos`).subscribe((response: any) => {
        this._videos = response;

        this._videos = this._videos.map(item => {
          return new Video(item);
        });

        this.onVideoChanged.next(this._videos);
        resolve(this._videos);
      }, reject);
    });
  }
}
