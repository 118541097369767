import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InstallationLocation } from 'app/shared/models';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { OdooRpcService } from '@cogito/angular-odoo';
import { crmTeamConfig, crmTagConfig } from 'app/configs';
import { CrmStageService } from './crm.stage.service';
import { FuseUtils } from '@fuse/utils';
import { CustomerStarService } from './customer.star.service';
import { AciService } from './aci.service';
import { CrmTagService } from './crm.tag.service';
import { userConfig } from '../../configs';

@Injectable()
export class InstallationLocationService {
  constructor(
    private _crmStageService: CrmStageService,
    private _customerStarService: CustomerStarService,
    private _http: HttpClient,
    private _odoo: OdooRpcService,
    private _aciService: AciService,
    private _crmTagService: CrmTagService,
  ) {
    // Set the defaults
  }
  // Add New Customer Info
  addZipcode(newZip: InstallationLocation): Promise<any> {
    return this._http.post(`/api/salespro/installationLocation`, newZip).toPromise();
  }

  // Get InstallationLocation Info
  getLocationByZip(zipcode: string): Promise<InstallationLocation> {
    const apiUrl = `/api/salespro/installationLocation/${zipcode}`;
    return new Promise((resolve, reject) => {
      this._http.get(apiUrl).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
}
