import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';
import { ApiAuthService } from '../services/api.auth.service';
@Injectable()
export class AuthGuard  {
  timeInterval: Subscription;
  constructor(private _auth: AuthService, private _router: Router,private apiAuthService :ApiAuthService) {
    // this.redirectWotSession();
  }

  redirectWotSession() {
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
    this.timeInterval = interval(5000).subscribe((x) => {
      const res = this.getCookie('session_id');
      if (!res) {
        this.timeInterval.unsubscribe();
        this._auth.logout();
      }
    });
  }

  private getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  OnDestroy() {
    this.timeInterval.unsubscribe();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      try {
        const isLoggedIn = await this._auth.isLoggedIn();
        const currentPermission = await this._auth.getCurrentPermission();
        const currentUser = this._auth.currentAuthValue;
        this.redirectWotSession();
        if(currentPermission){
        const permission = await this.apiAuthService.getAllPermissions(currentUser.email)
        if(localStorage.getItem('userRole')){
        const newRoles = permission.implicitRolesForUser ;
        const oldRoles = JSON.parse(localStorage.getItem('allRoles')).implicitRolesForUser;
        const checkRoles = JSON.stringify(oldRoles) === JSON.stringify(newRoles);
        if(!checkRoles){
          this._auth.logout();
        }
      }                     
        localStorage.setItem('allRoles',JSON.stringify(permission));
      }
        if (isLoggedIn) {
          const permissionName = state.url.split('/')[1];
          const urlSegments = state.url.split('/');
          if (urlSegments.length === 2 && urlSegments[1] === currentPermission) {
            this._router.navigateByUrl(`/${currentPermission}/dashboard`);
            return resolve(false);
          }
          if (permissionName === currentPermission) {
            return resolve(true);
          }

          if (
            permissionName === 'customer' &&
            (currentPermission === 'admin' ||
              currentPermission === 'user')
          ) {
            return resolve(true);
          }
        }
      } catch (error) {
        console.log('Auth Guard Error', error);
      }

      this._router.navigateByUrl(`/auth/login`, /* Removed unsupported properties by Angular migration: queryParams. */ {});
      return resolve(false);
    });
  }
}
