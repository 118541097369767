export class History {
  id?: number | undefined;
  guid?: string | undefined;
  userName?: string | undefined;
  userEmail?: string | undefined;
  customerName?: string | undefined;
  customerEmail?: string | undefined;
  action?: string | undefined;
  actionDetail?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  display_name?: string | undefined;
  odooId?: number | undefined;
}
