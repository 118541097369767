import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class MelissadataService {
  constructor(private _http: HttpClient) {}

  verifyAddress(address: string, melissadataApi): Promise<any> {
    return this._http
      .get(
        `/api/melissadata/LookupProperty?id=${melissadataApi}&&ff=${address}&cols=GrpAll&format=json`,
      )
      .pipe(
        map((item: any) => item.Records[0]),
        catchError((error: any) => observableThrowError(error)),
      )
      .toPromise();
  }
}
