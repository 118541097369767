// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `quick-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 99;
}
quick-panel .mat-slide-toggle-content {
  flex: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInF1aWNrLXBhbmVsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsY0FBQTtFQUNBLFlBQUE7RUFDQSxnQkFBQTtFQUNBLGdCQUFBO0VBQ0EsV0FBQTtBQUNGO0FBQ0U7RUFDRSxPQUFBO0FBQ0oiLCJmaWxlIjoicXVpY2stcGFuZWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJxdWljay1wYW5lbCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGZsZXg6IDEgMSBhdXRvO1xuICB3aWR0aDogMjgwcHg7XG4gIG1pbi13aWR0aDogMjgwcHg7XG4gIG1heC13aWR0aDogMjgwcHg7XG4gIHotaW5kZXg6IDk5O1xuXG4gIC5tYXQtc2xpZGUtdG9nZ2xlLWNvbnRlbnQge1xuICAgIGZsZXg6IDE7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layout/components/quick-panel/quick-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AACF;AACE;EACE,OAAA;AACJ;AACA,4oBAA4oB","sourcesContent":["quick-panel {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  width: 280px;\n  min-width: 280px;\n  max-width: 280px;\n  z-index: 99;\n\n  .mat-slide-toggle-content {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
