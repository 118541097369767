import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { HttpInterceptor } from './interceptors/http.interceptor';
import { PackageService } from './services/package.service';
import { AuthService } from './auth/auth.service';
import { BranchService } from './services/branch.service';
import { ProductService } from './services/product.service';
import { AuthGuard } from './guards/auth.guard';
import { UserService } from './services/user.service';
import { MelissadataService } from './services/melissadata.service';
import { CustomerService } from './services/customer.service';
import { UserBranchService } from './services/user.branch.service';
import { VideoService } from './services/video.service';
import { DocusignService } from './services/docusign.service';
import { OdooRpcModule } from '@cogito/angular-odoo';
import { CrmStageService } from './services/crm.stage.service';
import { CustomerStarService } from './services/customer.star.service';
import { HistoryService } from './services/history.service';
import { AciService } from './services/aci.service';
import { ApiAuthService } from './services/api.auth.service';
import { CrmTagService } from './services/crm.tag.service';
import { SendgridService } from './services/sendgrid.service';
import { InstallationLocationService } from './services/installation.location.service';
import { ConfigurationService } from './services/configuration.service';
import { ProductCategoryService } from './services/product.category.service';
import { CustomerNewService } from './services/customer-new.service';
import { TaskScheduleService } from './services/task-schedule.service';
import { PaymentLogService } from './services/payment-log.service';
import { SelfgenService } from './services/selfgen.service';
import { LogsService } from './services/logs.service';
import { AssociationService } from './services/association.service';
import {ResponseInterceptor} from './interceptors/response.interceptor';
import {VersionInterceptor} from './interceptors/version.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { PermissionsService } from './services/permissions.service';
import { UsersGroupService } from './services/users.group.service';
import { CustomerInterceptor } from './interceptors/customer.interceptor';
import { CustomerGuard } from './guards/customer.guard';

@NgModule({
  imports: [HttpClientModule, OdooRpcModule , BrowserAnimationsModule,MatDialogModule],
  exports: [],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomerInterceptor,
      multi: true,
    },

    AuthGuard,
    CustomerGuard,

    AciService,
    AuthService,
    BranchService,
    CrmStageService,
    CustomerService,
    CustomerStarService,
    DocusignService,
    HistoryService,
    MelissadataService,
    PackageService,
    ProductService,
    UserService,
    UserBranchService,
    VideoService,
    ApiAuthService,
    SendgridService,
    CrmTagService,
    InstallationLocationService,
    ConfigurationService,
    ProductCategoryService,
    CustomerNewService,
    TaskScheduleService,
    PaymentLogService,
    SelfgenService,
    LogsService,
    AssociationService,
    PermissionsService,
    UsersGroupService,
  ],
})
export class CoreModule {}
