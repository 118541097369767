export class ProductCategory {
  id?: number;
  guid?: string;
  name?: string;
  order?: number;
  createdAt?: string;
  updatedAt?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.name !== undefined) {
      this.name = item.name;
    }
    if (item.order !== undefined) {
      this.order = item.order;
    }
    if (item.createdAt !== undefined) {
      this.createdAt = item.createdAt;
    }
    if (item.updatedAt !== undefined) {
      this.updatedAt = item.updatedAt;
    }
  }
}
