import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { environment } from 'environments/environment';
import { userConfig } from '../../configs';

@Injectable()
export class ApiAuthService {
  constructor(private _http: HttpClient) {}

  generateToken(sessionId: string): Promise<any> {
    return this._http
      .post(`/api/salespro/auth`, { sessionId })
      .pipe(
        map((item: any) => {
          const token = item.token;
          const {
            storage: {
              auth: { TOKEN },
            },
          } = userConfig;
          localStorage.setItem(TOKEN, token);
        }),
        catchError((error: any) => observableThrowError(error)),
      )
      .toPromise();
  }
  getAuthorizationToken(): string {
    const {
      storage: {
        auth: { TOKEN },
      },
    } = userConfig;
    return localStorage.getItem(TOKEN);
  }

  logout(): void {
    const userPermissions = localStorage.getItem('userPermissions');
    const isPermissionSet = localStorage.getItem('isPermissionSet');
    const allRoles = localStorage.getItem('allRoles');
    const userRole = localStorage.getItem('userRole');
    if (isPermissionSet) {
      localStorage.removeItem('isPermissionSet');
    }
    if (userPermissions) {
      localStorage.removeItem('userPermissions');
    }
    if(allRoles){
      localStorage.removeItem('allRoles');
    }
    if(userRole){
      localStorage.removeItem('userRole');
    }
    const {
      storage: {
        auth: { TOKEN },
      },
    } = userConfig;
    localStorage.setItem(TOKEN, '');
  }
  getAllPermissions(user : any) : Promise<any>{
    return new Promise ((resolve,reject)=>{
      this._http.post(`/api/salespro/permission/getUserPermissions`,{
        sub : user
      }).subscribe((response):any =>{
          resolve(response);
        },
        error =>{
         reject(error.error);
        }
        )
    })
  }
}
