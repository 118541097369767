import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customer, CustomerStar } from 'app/shared/models';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { OdooRpcService, ɵa } from '@cogito/angular-odoo';
import { crmTeamConfig, crmTagConfig } from 'app/configs';
import { CrmStageService } from './crm.stage.service';
import { FuseUtils } from '@fuse/utils';
import { CustomerStarService } from './customer.star.service';
import { AciService } from './aci.service';
import { CrmTagService } from './crm.tag.service';
import { userConfig } from '../../configs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable()
export class CustomerService  {
  onCustomerChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<string>;
  onSearchTextChanged: Subject<any>;

  private _customers: any[];
  private _localCustomers: any[];

  constructor(
    private _crmStageService: CrmStageService,
    private _customerStarService: CustomerStarService,
    private _http: HttpClient,
    private _odoo: OdooRpcService,
    private _aciService: AciService,
    private _crmTagService: CrmTagService,
    private _odooCokkies: ɵa,
    private _authService: AuthService,
  ) {
    // Set the defaults
    this._customers = [];
    this._localCustomers = [];
    this.onCustomerChanged = new BehaviorSubject(this._customers);
    this.onFilterChanged = new Subject();
    this.onSearchTextChanged = new Subject();
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise(async (resolve, reject) => {
      Promise.all([
        this.getAllOdooCustomers(),
        this._customerStarService.getAllCustomerStars(),
        this.getAllCustomers(),
      ]).then((result) => {
        this._customers = result[0];

        const customerStars: CustomerStar[] = result[1];
        this._localCustomers = result[2];

        const localCustomerOdooId = this._localCustomers.map((item) => {
          return item.odooId;
        });
        this._customers = this._customers.map((item) => {
          const isNewTrimLead =
            item.leadType === 'Trim Leads' &&
            localCustomerOdooId &&
            localCustomerOdooId.indexOf(item.leadId) === -1;
          return { ...item, isNewTrimLead };
        });

        for (const star of customerStars) {
          const matchedItem = this._customers.find((item) => item.leadId === star.odooId);
          if (matchedItem) {
            matchedItem.isStarred = true;
          }
        }

        this.onCustomerChanged.next(this._customers);
        this.onSearchTextChanged.subscribe((searchText) => {
          const filteredCustomers = FuseUtils.filterArrayByString(this._customers, searchText);
          this.onCustomerChanged.next(filteredCustomers);
        });

        this.onFilterChanged.subscribe((filterBy) => {
          let filteredCustomers = [];
          switch (filterBy) {
            case 'all':
              filteredCustomers = this._customers;
              break;
            case 'working-opportunity':
              filteredCustomers = this._customers.filter(
                (item) => item.status === 'Working Opportunity',
              );
              break;
            case 'closed-won':
              filteredCustomers = this._customers.filter((item) => item.status === 'Closed - WON');
              break;
            case 'installed':
              filteredCustomers = this._customers.filter(
                (item) => item.status === 'Install Scheduled',
              );
              break;
            case 'trimmed':
              filteredCustomers = this._customers.filter((item) => item.isTrimmed === true);
              break;
            case 'referral':
              filteredCustomers = this._customers.filter((item) => item.leadType === 'Referral');
              break;
            case 'appt-set':
              filteredCustomers = this._customers.filter(
                (item) =>
                  item.status === 'Appt Scheduled' || item.status === 'Appointment Scheduled',
              );
              break;
            case 'self-gen':
              filteredCustomers = this._customers.filter((item) => item.isSelfGen === true);
              break;
            case 'starred':
              filteredCustomers = this._customers.filter((item) => item.isStarred === true);
              break;
            default:
              filteredCustomers = this._customers;
              break;
          }
          this.onCustomerChanged.next(filteredCustomers);
        });
        resolve(this._customers);
      }, reject);
    });
  }

  async getFundingUrl(
    _customerGuid,
    _odooCustomerId,
    paymentType,
    paymentStatus,
    _remote = '',
  ): Promise<any> {
    const billingAccountNumber = new Date().getTime();

    return new Promise(async (resolve, reject) => {
      try {
        const fundingResult = await this._aciService.addFunding(
          paymentType || 'cash',
          paymentStatus || undefined,
          billingAccountNumber,
          _customerGuid,
          _odooCustomerId,
          _remote,
        );
        const fundingResultCode =
          fundingResult['add-funding-response'].message[0]['message-code'][0];
        const fundingUrl = fundingResult['add-funding-response']['add-funding-url'][0];

        return resolve({
          fundingResultCode,
          fundingUrl,
        });
      } catch (error) {
        return reject(error);
      }
    });
  }
  addInternalNote(customerId, message: string, attachmentIds: number[] = []): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const newNoteId = await this._odoo
          .call('crm.lead', 'message_post', [customerId], {
            attachment_ids: attachmentIds,
            body: message,
            content_subtype: 'html',
            message_type: 'comment',
            partner_ids: [],
            subtype: 'mail.mt_note',
          })
          .toPromise();
        const newNote = await this._odoo
          .call('mail.message', 'message_format', [newNoteId], {})
          .toPromise<any>();
        return resolve({
          author: newNote[0].author_id[1],
          body: newNote[0].body,
          date: newNote[0].date,
          type: newNote[0].message_type,
          subTypeDescription: newNote[0].subtype_description,
          tracking: newNote[0].tracking_value_ids,
        });
      } catch (error) {
        return reject(error);
      }
    });
  }

  uploadAttachment(odooCustomerId, attachment: any, fileName: string): Promise<number> {
    return this._odoo
      .call(
        'ir.attachment',
        'create',
        [
          {
            res_model: 'crm.lead',
            res_id: odooCustomerId,
            name: fileName,
            type: 'binary',
            datas: btoa(attachment),
            datas_fname: fileName,
          },
        ],
        {},
      )
      .toPromise();
  }
  // Get Customer Info
  uploadAttachmentByApi(odooCustomerId, attachment: any, fileName: string): Promise<number> {
    const apiUrl = `/api/salespro/customers/remote/uploadAttachmentByApi`;

    return new Promise((resolve, reject) => {
      this._http
        .post(apiUrl, {
          odooCustomerId,
          attachment,
          fileName,
        })
        .subscribe((response: any) => {}, reject);
    });
  }

  // Get Odoo Customer Detail Info by Id
  getOdooCustomerDetailsById(id): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await this._odoo
          .call('crm.lead', 'search_read', [[['id', '=', id]]], {
            fields: [
              'display_name',
              'probability',
              'referred',
              'stage_id',
              'team_id',
              'email_from',
              'user_id',
              'partner_id',
              'phone',
              'tag_ids',
              'street',
              'street2',
              'city',
              'state_id',
              'zip',
              'country_id',
              'name',
              'site_id',
              'message_ids',
              'builder_id',
              'community_id',
              'contact_name',
              'crm_lead_type_id',
            ],
          })
          .toPromise<any>();
        customer = customer[0];

        let customerMessages = [];
        if (customer.message_ids && customer.message_ids.length > 0) {
          const messageDomain = [];
          for (let i = 0; i < customer.message_ids.length - 1; i++) {
            messageDomain.push('|');
          }
          for (const mesId of customer.message_ids) {
            messageDomain.push(['id', '=', mesId]);
          }
          customerMessages = await this._odoo
            .call('mail.message', 'message_format', [customer.message_ids], {})
            .toPromise<any>();
          customerMessages = customerMessages.map((item) => {
            return {
              author: item.author_id[1],
              body: item.body,
              date: item.date,
              type: item.message_type,
              subTypeDescription: item.subtype_description,
              tracking: item.tracking_value_ids,
            };
          });
          console.log(customerMessages);
        }

        const tags = await this._prepareTags();
        const tagName = this._getTagName(customer.tag_ids, tags);
        let isSelfGen = false;
        let isTrimmed = false;

        if (customer.tag_ids && customer.tag_ids.length > 0) {
          isSelfGen = customer.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1 ? true : false;
          isTrimmed = customer.tag_ids.indexOf(environment.TRIMMED_LEAD_ID) > -1 ? true : false;
        }
        let street = customer.street === false ? '' : customer.street.toString().trim();
        street += customer.street2 === false ? '' : ' ' + customer.street2.toString().trim();

        resolve({
          isOdoo: true,
          address: this.prepareCustomerAddress(customer),
          street,
          city: customer.city === false ? '' : customer.city.toString().trim(),
          state: customer.state_id === false ? '' : customer.state_id[1].toString().trim(),
          zip: customer.zip === false ? '' : customer.zip.toString().trim(),
          builderName: customer.builder_id ? customer.builder_id[1].toString().trim() : '',
          community: customer.community_id ? customer.community_id[1].toString().trim() : '',
          displayName: customer.display_name,
          email: customer.email_from,
          phone: customer.phone,
          salesPerson: customer.user_id[1],
          salesType: customer.team_id[1],
          status: tagName ? tagName : customer.stage_id[1],
          messages: customerMessages,
          tag_ids: customer.tag_ids,
          name: customer.name,
          siteName: customer.site_id === false ? '' : customer.site_id[1].toString().trim(),
          country: customer.country_id === false ? '' : customer.country_id[1].toString().trim(),
          isSelfGen,
          isTrimmed,
          contact_name: customer.contact_name ? customer.contact_name : '',
          crm_lead_type_id:
            customer.crm_lead_type_id === undefined || customer.crm_lead_type_id === false
              ? 6
              : customer.crm_lead_type_id[1].toString().trim(),
        });
      } catch (error) {
        reject(error);
      }
    });
  }
  prepareCustomerName(newCustomer) {
    let name = '';
    // if (newCustomer.streetType) {
    //   name = `${newCustomer.streetNumber} ${newCustomer.streetName}
    // ${newCustomer.streetType}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // } else {
    //   name = `${newCustomer.streetNumber} ${newCustomer.streetName}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // }
    // if (newCustomer.streetType) {
    //   name = `${newCustomer.streetName} ${newCustomer.streetType}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // } else {
    //   name = `${newCustomer.streetName}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // }
    name = `${newCustomer.streetName}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    let nameString = name; // just an example
    nameString = nameString.replace(/[^\w\s]/gi, '');

    if (nameString.trim() === '') {
      return `${newCustomer.firstName} ${newCustomer.lastName}`;
    } else {
      return name;
    }
  }

  // private function prepareCustomerAddress
  prepareCustomerAddress(customer): string {
    let customerAddress = customer.street === false ? '' : customer.street.toString().trim();
    customerAddress += customer.street2 === false ? '' : ' ' + customer.street2.toString().trim();
    if (customer.city !== false || customer.state !== false) {
      customerAddress += ',';
      customerAddress += customer.city === false ? '' : ' ' + customer.city.toString().trim();
      customerAddress +=
        customer.state_id === false ? '' : ' ' + customer.state_id.toString().trim();
    }
    customerAddress += customer.zip === false ? '' : ', ' + customer.zip.toString().trim();
    customerAddress +=
      customer.country_id === false ? '' : ', ' + customer.country_id.toString().trim();
    return customerAddress.trim();
  }

  // Get All Odoo Customer Info
  private _prepareTags(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const allTags = await this._crmTagService.getAllCrmTags();
        const tags = [];
        for (const tagName of Object.values(crmTagConfig.status)) {
          const selectedTag = allTags.find((tag: any) => {
            return tag && tag.name === tagName;
          });
          if (selectedTag && selectedTag.id) {
            tags.push(selectedTag);
          }
        }
        resolve(tags);
      } catch (error) {
        reject(error);
      }
    });
  }

  private _getTagName(tagIds: Array<number>, tags: any): string {
    let tagName = '';
    for (const tagId of tagIds) {
      const selectedTag = tags.find((tag: any) => {
        return tag && tag.id === tagId;
      });
      if (selectedTag && selectedTag.name) {
        tagName = selectedTag.name;
      }
    }
    return tagName;
  }

  getAllGroups(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const usersByGroups = await this._odoo
          .call('res.groups', 'search_read', [], {})
          .toPromise();
        let users = [];
        usersByGroups.map((group) => {
          users = [...users, ...group.users];
        });
        resolve(usersByGroups);
      } catch (error) {
        reject(error);
      }
    });
  }

  // Get All Odoo Customer Info
  getAllOdooCustomers(): Promise<Customer[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const usersByGroups = await this._odoo
          .call(
            'res.groups',
            'search_read',
            [
              [
                '|',
                '|',
                ['name', '=', environment.ADMIN_ODOO_GROUP],
                ['name', '=', environment.MANAGER_ODOO_GROUP],
                ['name', '=', environment.USER_ODOO_GROUP],
              ],
            ],
            {},
          )
          .toPromise();

        let users = [];

        usersByGroups.map((group) => {
          users = [...users, ...group.users];
        });

        const domain = [];
        domain.push('&');

        for (let i = 1; i < users.length; i++) {
          domain.push('|');
        }
        for (const userId of users) {
          domain.push(['user_id', '=', userId]);
        }
        domain.push(['type', '=', 'opportunity']);
        domain.push(['team_id', '=', crmTeamConfig.LEAD_GENERATION.id]);
        const tags = await this._prepareTags();

        let customers = await this._odoo
          .call('crm.lead', 'search_read', [domain], {
            fields: [
              'display_name',
              'referred',
              'stage_id',
              'tag_ids',
              'team_id',
              'street',
              'street2',
              'city',
              'state_id',
              'zip',
              'country_id',
              'user_id',
            ],
            order: 'write_date DESC',
          })
          .toPromise<any[]>();
        customers = customers.map((item) => {
          const tagName = this._getTagName(item.tag_ids, tags);
          let isSelfGen = false;
          let isTrimmed = false;

          if (item.tag_ids && item.tag_ids.length > 0) {
            isSelfGen = item.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1 ? true : false;
            isTrimmed = item.tag_ids.indexOf(environment.TRIMMED_LEAD_ID) > -1 ? true : false;
          }

          return {
            isOdoo: true,
            leadId: item.id,
            displayName: item.display_name,
            leadType: item.referred
              ? 'Referral'
              : item.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1
              ? 'Self Gen'
              : 'Trimmed',
            status: tagName ? tagName : item.stage_id[1],
            isStarred: false,
            address: this.prepareCustomerAddress(item),
            isSelfGen,
            isTrimmed,
            salesPerson: item.user_id === false ? '' : item.user_id[1].toString().trim(),
          };
        });
        resolve(customers);
      } catch (error) {
        reject(error);
      }
    });
  }

  // Get All Customer Info
  getAllCustomers(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/customers`).subscribe((response: any) => {
        let values: any[] = response;
        values = values.map((item) => {
          return new Customer(item);
        });
        resolve(values);
      }, reject);
    });
  }

  // Get Customer Info
  getCustomerByGuid(guid: string, remote = ''): Promise<Customer> {
    let apiUrl = `/api/salespro/customers/${guid}`;
    if (remote === 'remote') {
      apiUrl = `/api/salespro/customers/remote/${guid}`;
    }
    return new Promise((resolve, reject) => {
      this._http.get(apiUrl).subscribe((response: any) => {
        const customer: Customer = new Customer(response);
        resolve(customer);
      }, reject);
    });
  }

  // Retry sales API
  retrySalesApi(guid: string): Promise<Customer> {
    const apiUrl = `/api/salespro/docusign/retrySalesApi/${guid}`;
    return new Promise((resolve, reject) => {
      this._http.get(apiUrl).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  // Get Customer Info
  mockDocusignWebHook(guid: string): Promise<any> {
    const apiUrl = `/api/salespro/docusign/CompleteSiging/${guid}`;
    return this._http.post(apiUrl, {}).toPromise();
  }

  // Get Customer by oddo Id
  getCustomerByOdooId(odooId: string): Promise<Customer> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/customers/odooId/${odooId}`).subscribe((response: any) => {
        if (response) {
          const customer: Customer = new Customer(response);
          resolve(customer);
        } else {
          return resolve(null);
        }
      }, reject);
    });
  }

  // getTagId
  private _getOdooTagId(tagName: string): Promise<number> {
    return new Promise(async (resolve, reject) => {
      try {
        let currentTagId = 0;
        const tags = await this._crmTagService.getAllCrmTags();
        const selectedTag = tags.find((tag: any) => {
          return tag && tag.name === tagName;
        });
        if (selectedTag && selectedTag.id) {
          currentTagId = selectedTag.id;
        } else {
          currentTagId = await this._crmTagService.addNewCrmTag(tagName);
        }
        resolve(currentTagId);
      } catch (error) {
        return reject(error);
      }
    });
  }

  // Add New Odoo Customer
  addNewOdooCustomer(newCustomer: Customer): Promise<number> {
    return new Promise(async (resolve, reject) => {
      try {
        // Get the Current Stage from Odoo
        let currentTeamId = -1;
        switch (newCustomer.leadType) {
          case 'Trimmed':
            currentTeamId = crmTeamConfig.LEAD_GENERATION.id;
            break;
          case 'Self Gen':
            currentTeamId = crmTeamConfig.LEAD_GENERATION.id;
            break;
          default:
            currentTeamId = crmTeamConfig.LEAD_GENERATION.id;
            break;
        }
        if (currentTeamId <= -1) {
          return reject(`Cannot find the matched team.`);
        }
        const currentTagId = await this._getOdooTagId(crmTagConfig.status.CUSTOMER_INFO_ADDED); // can be replace by hardCoding
        let leadTypeTagId = environment.SELF_GEN_LEAD_ID;
        if (newCustomer.leadType === 'Trimmed') {
          leadTypeTagId = environment.TRIMMED_LEAD_ID;
        }

        const newCustomerId = await this._odoo
          .call(
            'crm.lead',
            'create',
            [
              {
                city: newCustomer.city,
                display_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
                email_from: newCustomer.email,
                name: this.prepareCustomerName(newCustomer),
                phone: newCustomer.formattedPhone,
                tag_ids: [[6, 0, [currentTagId, leadTypeTagId]]],
                // street: `${newCustomer.streetNumber} ${newCustomer.streetName} ${newCustomer.streetType}`,
                street: `${newCustomer.streetName}`,
                team_id: currentTeamId,
                type: 'opportunity',
                // x_contract: 38,
                zip: newCustomer.zipCode,
                referred: newCustomer.referred ? newCustomer.referred : false,
                contact_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
                crm_lead_type_id: 6,
              },
            ],
            {},
          )
          .toPromise();
        console.log('Created Customer Id', newCustomerId);

        const promises = [];

        promises.push(
          this._odoo
            .call('crm.lead', 'search_read', [[['id', '=', newCustomerId]]], {
              limit: 1,
            })
            .toPromise(),
        );

        promises.push(
          this.addInternalNote(
            newCustomerId,
            'Stage Changed' +
              '<ul>' +
              '<li>' +
              '    Stage:' +
              '        <span> ' +
              crmTagConfig.status.CUSTOMER_INFO_ADDED +
              '</li>' +
              '</ul>',
          ),
        );

        const [newOdooCustomer] = await Promise.all(promises);

        console.log('New Odoo Customer', newOdooCustomer);
        resolve(newCustomerId);
      } catch (error) {
        return reject(error);
      }
    });
  }

  // updateTrimmedOdooCustomer
  updateTrimmedOdooCustomer(
    odooCustomerId: number,
    newCustomer: any,
    tagIds: Array<number>,
    siteName: null,
    address: null,
  ) {
    const currentTeamId = crmTeamConfig.LEAD_GENERATION.id;

    return new Promise(async (resolve, reject) => {
      try {
        const { currentTagId, exitingTags, previousTagName } = await this._prepareTagsForUpdate(
          tagIds,
          crmTagConfig.status.CUSTOMER_INFO_ADDED,
        );
        const currentUser = this._authService.currentAuthValue;
        const updateContent = {
          // display_name: newCustomer.streetName
          //   ? `${address}`
          //   : siteName
          //   ? `${siteName}`
          //   : `${newCustomer.firstName} ${newCustomer.lastName}`,
          display_name: newCustomer.display_name,
          email_from: newCustomer.email,
          name: newCustomer.streetName
            ? `${address}`
            : siteName
            ? `${siteName}`
            : newCustomer.display_name
            ? newCustomer.display_name
            : this.prepareCustomerName(newCustomer),
          phone: newCustomer.formattedPhone,
          team_id: currentTeamId,
          type: 'opportunity',
          // x_contract: 38,
          tag_ids: [[6, 0, exitingTags]],
          referred: newCustomer.referred ? newCustomer.referred : false,
          contact_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
          city: newCustomer.city ? newCustomer.city : '',
          zip: newCustomer.zipCode ? newCustomer.zipCode : '',
          user_id: currentUser.uid,
          crm_lead_type_id: newCustomer.crm_lead_type_id,
        };
        console.log('Update Content...haha...', updateContent);
        const promises = [];

        promises.push(
          this._odoo.call('crm.lead', 'write', [[odooCustomerId], updateContent], {}).toPromise(),
        );

        promises.push(
          this.addInternalNote(
            odooCustomerId,
            'Stage Changed' +
              '<ul>' +
              '<li>' +
              '    Stage:' +
              '        <span> ' +
              crmTagConfig.status.CUSTOMER_INFO_ADDED +
              '</li>' +
              '</ul>',
          ),
        );
        await Promise.all(promises);
        return resolve('success');
      } catch (error) {
        return reject(error);
      }
    });
  }

  // Add New Customer Info
  addNewCustomer(newCustomer: Customer) {
    return new Promise((resolve, reject) => {
      return this._http.post(`/api/salespro/customers`, newCustomer).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  // Update the Selected Customer Info
  updateCustomer(customerGuid: string, updatedContent: any, remote = ''): Promise<any> {
    console.log('customer update...........', updatedContent);
    let sUrl = `/api/salespro/customers/${customerGuid}`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/customers/remote/${customerGuid}`;
    }
    return this._http.patch(sUrl, updatedContent).toPromise();
  }

  // Update the Selected Odoo Customer Info
  updateOdooCustomer(odooCustomerId: number, updateContent: any) {
    console.log('Update Content', updateContent);
    return new Promise((resolve, reject) => {
      this._odoo
        .call('crm.lead', 'write', [[odooCustomerId], updateContent], {})
        .subscribe((updated: any) => {
          console.log('Updated Odoo Customer', updated);
          return resolve(updated);
        }, reject);
    });
  }

  // Preparing tags for update
  private _prepareTagsForUpdate(tagIds: Array<number>, tagName: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const tagPromises = [this._prepareTags(), this._getOdooTagId(tagName)];
        const [tags, currentTagId] = await Promise.all(tagPromises);

        const exitingTags = [];
        let previousTagName = '';
        if (typeof tagIds !== 'undefined' && tagIds) {
          for (const tagId of tagIds) {
            const selectedTag = tags.find((tag: any) => {
              return tag && tag.id === tagId;
            });
            if (!selectedTag) {
              exitingTags.push(tagId);
            } else {
              previousTagName = selectedTag.name;
            }
          }
        }
        if (currentTagId) {
          exitingTags.push(currentTagId);
        }
        return resolve({ exitingTags, previousTagName, currentTagId });
      } catch (error) {
        return reject(error);
      }
    });
  }

  // updateOdooCustomerStage(odooCustomerId: number, updatedStageName: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let odooCustomer = await this._odoo
  //         .call('crm.lead', 'search_read', [[['id', '=', odooCustomerId]]], {
  //           fields: ['tag_ids', 'team_id'],
  //           limit: 1,
  //         })
  //         .toPromise<any>();
  //       console.log('Updated Odoo Customer', odooCustomer);
  //       if (!odooCustomer || odooCustomer.length <= 0) {
  //         return reject('Odoo Customer is not existed.');
  //       }
  //       odooCustomer = odooCustomer[0];

  //       const { currentTagId, exitingTags, previousTagName } = await this._prepareTagsForUpdate(
  //         odooCustomer.tag_ids,
  //         updatedStageName,
  //       );

  //       if (currentTagId) {
  //         const updateTagPromises = [
  //           this.updateOdooCustomer(odooCustomerId, {
  //             tag_ids: [[6, 0, exitingTags]],
  //           }),
  //         ];

  //         if (previousTagName) {
  //           updateTagPromises.push(
  //             this.addNewInternalNote(
  //               odooCustomerId,
  //               'Stage Changed' +
  //                 '<ul>' +
  //                 '<li>' +
  //                 '    Stage:' +
  //                 '        <span> ' +
  //                 previousTagName +
  //                 ' </span>' +
  //                 '        <span>-></span>' +
  //                 '    <span>' +
  //                 updatedStageName +
  //                 '    </span>' +
  //                 '</li>' +
  //                 '</ul>',
  //             ),
  //           );
  //         }
  //         await Promise.all(updateTagPromises);
  //       } else {
  //         reject('Failed To update tag');
  //       }
  //       return resolve('success');
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  // Local Changes
  localUpdateCustomerIsStarred(customer: any, isStarred: boolean) {
    const customerIndex = this._customers.indexOf(customer);
    this._customers[customerIndex].isStarred = isStarred;
    if (isStarred) {
      return this._customerStarService.addNewCustomerStar(
        new CustomerStar({
          odooId: customer.leadId,
        }),
      );
    } else {
      return this._customerStarService.removeCustomerStar(
        new CustomerStar({
          odooId: customer.leadId,
        }),
      );
    }
  }
  updateOddoTag(customerGuid: string, updatedStageName: string): Promise<any> {
    const sUrl = `/api/salespro/customers/remote/updateOddoTag`;
    return this._http.post(sUrl, { customerGuid, updatedStageName }).toPromise();
  }

  uploadAttachmentByAPI(odooCustomerId, attachment: any, fileName: string): Promise<any> {
    const sUrl = `/api/salespro/customers/remote/uploadAttachmentByAPI`;
    return this._http.post(sUrl, { odooCustomerId, attachment, fileName }).toPromise();
  }
  // Get Customer Info
  addNewInternalNote(
    customerGuid,
    message: string,
    attachmentIds: number[] = [],
  ): Promise<any> {
    const apiUrl = `/api/salespro/customers/remote/addRemoteNewInternalNote`;
    return this._http.post(apiUrl, { customerGuid, message, attachmentIds }).toPromise();
  }
  salesComplete(completeData): Promise<any> {
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/sale/${db}`;
    const sessionId = this._odooCokkies.getSessionId();
    console.log('===sessionId==', sessionId);
    return this._http
      .post(sUrl, completeData, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Openerp': sessionId,
        }),
      })
      .pipe(
        map(async (item) => {
          return item;
        }),
      )
      .toPromise();
  }
  generateSchedulingUrl(guid: string) {
    // const apiUrl = `/api/salespro/customers/${guid}`;
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/sale/${db}?${guid}`;
    const sessionId = this._odooCokkies.getSessionId();
    return new Promise((resolve, reject) => {
      this._http.get(sUrl).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
}
