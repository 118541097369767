import { crmTagConfig } from './crm.tag';

export const remoteExpirationStage = [
  crmTagConfig.status.CUSTOMER_INFO_ADDED,
  crmTagConfig.status.PLAN_SELECTED,
  crmTagConfig.status.PRODUCT_SELECTED,
  crmTagConfig.status.PRODUCT_REVIEWED,
  crmTagConfig.status.PAYMENT_REVIEWED,
  crmTagConfig.status.PAYMENT_SKIPPED,
  crmTagConfig.status.RESET_WORKFLOW,
  crmTagConfig.status.CUSTOMER_NEW_INFO_ADDED,
];
