export class Association {
  id?: number;
  configuration?: string;

  createdAt?: string;
  updatedAt?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.configuration !== undefined) {
      this.configuration = item.configuration;
    }
  }
}
