export class Builder {
  id?: number;
  guid?: string;
  name?: string;
  odooId?: number;
  fileName?: string;
  products?: number;
  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }
    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.name !== undefined) {
      this.name = item.name;
    }
    if (item.odooId !== undefined) {
      this.odooId = item.odooId;
    }
    if (item.fileName !== undefined) {
      this.fileName = item.fileName;
    }
    if (item.products !== undefined) {
      this.products = item.products;
    }
  }
}
