import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';

import { navigation as navigationAdmin } from './navigation/navigation.admin';
import { navigation as navigationManager } from './navigation/navigation.manager';
import { navigation as navigationUser } from './navigation/navigation';

import { locale as navigationEnglish } from 'app/shared/i18n/en';
import { takeUntil } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import {
  Router,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  title = 'SalesPro';

  isSettingsVisible = true;

  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _platform: Platform,
    private _router: Router,
    private _translateService: TranslateService,
    public matIconRegistry: MatIconRegistry,
  ) {
    // Register FontAwesome Icons on Material Icons
    this.matIconRegistry.registerFontClassAlias('mdi');

    // Get the default navigation
    this.navigation = navigationUser;

    // Register the navigation to the service.
    this._fuseNavigationService.register('admin', navigationAdmin);
    this._fuseNavigationService.register('manager', navigationManager);
    this._fuseNavigationService.register('user', navigationUser);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('user');

    // Add languages.
    this._translateService.addLangs(['en', 'es']);

    // Set the default language.
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(navigationEnglish);

    // Use a language
    this._translateService.use('en');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this._document.body.classList.add('is-mobile');
    }

    // Loader for navigation
    this._router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this._fuseProgressBarService.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this._fuseProgressBarService.hide();
          break;
        }
        default: {
          break;
        }
      }
    });

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    // Subscribe to the fuse config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;

      // Boxed
      if (this.fuseConfig.layout.width === 'boxed') {
        this._document.body.classList.add('boxed');
      } else {
        this._document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      for (const className of this._document.body.classList) {
        if (className.startsWith('theme-')) {
          this._document.body.classList.remove(className);
        }
      }

      this._document.body.classList.add(this.fuseConfig.colorTheme);
    });

    this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          (event.url && event.url.startsWith('/auth/login')) ||
          (event.url && event.url.startsWith('/auth/remote/'))
        ) {
          this.isSettingsVisible = false;
        } else {
          this.isSettingsVisible = true;
        }
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key Sidebar Key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
