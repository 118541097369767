import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'user-dashboard',
    title: 'Dashboard',
    translate: 'NAV.USER.DASHBOARD',
    type: 'item',
    icon: 'email',
    url: '/user/dashboard'
  }
];
