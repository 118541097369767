import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.scss'
  ]
})
export class FooterComponent {
  /**
   * Constructor
   */
  constructor() {}
}
