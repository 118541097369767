import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from './configs';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { LayoutModule } from './layout/layout.module';

import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ErrorModule } from './modules/error/error.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    TranslateModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),

    // Material Modules
    MatButtonModule,
    MatIconModule,

    // Fuse Modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // Core Modules
    AppRoutingModule,
    CoreModule,
    LayoutModule,
    ErrorModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
