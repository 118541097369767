import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { OdooRpcService, ɵa } from '@cogito/angular-odoo';
import { map } from 'rxjs/operators';
@Injectable()
export class TaskScheduleService {
  constructor(private _http: HttpClient, private _odooCokkies: ɵa) {}
  getEvents(start: string, taskId: string) {
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/task-schedule/events/${db}?start=${start}&task_id=${taskId}`;
    /* const sUrl = `https://dev-nyc3-odoo02.homeprotech.com/api/v1/task-schedule/events/${db}?start=${start}&task_id=${taskId}`;*/
    const sessionId = this._odooCokkies.getSessionId();
    console.log('===sessionId==', sessionId);

    return new Promise((resolve, reject) => {
      this._http
        .get(sUrl, {
          headers: new HttpHeaders({
            'X-Openerp': sessionId,
          }),
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  bookSlot(day: string, shift: string, task_id: string) {
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/task-schedule/book/${db}?day=${day}&shift=${shift}&task_id=${task_id}`;

    const sessionId = this._odooCokkies.getSessionId();
    console.log('===sessionId==', sessionId);

    return new Promise((resolve, reject) => {
      this._http
        .get(sUrl, {
          headers: new HttpHeaders({
            'X-Openerp': sessionId,
          }),
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getLabourCalendarEvents(payload) {
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/task-schedule/events_rocal`;
    /* const sUrl = `https://dev-nyc3-odoo02.homeprotech.com/api/v1/task-schedule/events/${db}?start=${start}&task_id=${taskId}`;*/
    const sessionId = this._odooCokkies.getSessionId();
    console.log('===sessionId==', sessionId);

    return new Promise((resolve, reject) => {
      this._http
        .post(sUrl, payload, {
          headers: new HttpHeaders({
            'X-Openerp': sessionId,
          }),
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
