import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CustomerInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const frontendPath = window.location.pathname;
        const trace_id = localStorage.getItem("trace_id");
        if (frontendPath.startsWith('/customer') && trace_id) {
            const modifiedRequest = request.clone({
                setHeaders: {
                    'X-Trace-ID': trace_id
                }
            });
            return next.handle(modifiedRequest);
        }
        return next.handle(request);
    }
}
