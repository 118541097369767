export class Product {
  id?: number;
  guid?: string;
  part: string;
  description?: string;
  basePrice: number;
  listPrice: number;
  packages: string;
  labor?: number;
  taggedProducts?: string;
  rmrAddOnItems?: string;
  isCamera?: boolean;
  isDoorBell?: boolean;
  createdAt?: string;
  updatedAt?: string;
  isSmokeDetector?: boolean;
  categoryGuid?: string;
  odooId?: string;
  builders?: string;
  popupText?: string;
  fileName?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.part !== undefined) {
      this.part = item.part;
    }
    if (item.description !== undefined) {
      this.description = item.description;
    }
    if (item.basePrice !== undefined) {
      this.basePrice = item.basePrice;
    }
    if (item.listPrice !== undefined) {
      this.listPrice = item.listPrice;
    }
    if (item.packages !== undefined) {
      this.packages = item.packages;
    }
    if (item.labor !== undefined) {
      this.labor = item.labor;
    }
    if (item.rmrAddOnItems !== undefined) {
      this.rmrAddOnItems = item.rmrAddOnItems;
    }
    if (item.taggedProducts !== undefined) {
      this.taggedProducts = item.taggedProducts;
    }
    if (item.isCamera !== undefined) {
      this.isCamera = item.isCamera;
    }
    if (item.isDoorBell !== undefined) {
      this.isDoorBell = item.isDoorBell;
    }
    if (item.createdAt !== undefined) {
      this.createdAt = item.createdAt;
    }
    if (item.updatedAt !== undefined) {
      this.updatedAt = item.updatedAt;
    }
    if (item.isSmokeDetector !== undefined) {
      this.isSmokeDetector = item.isSmokeDetector;
    }
    if (item.categoryGuid !== undefined) {
      this.categoryGuid = item.categoryGuid;
    }
    if (item.odooId !== undefined) {
      this.odooId = item.odooId;
    }

    if (item.builders !== undefined) {
      this.builders = item.builders;
    }

    if (item.popupText !== undefined) {
      this.popupText = item.popupText;
    }
    if (item.fileName !== undefined) {
      this.fileName = item.fileName;
    }
  }
}
