import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DocusignService {
  constructor(private _http: HttpClient) {}

  authenticate(): Promise<any> {
    return this._http.get(`/api/salespro/docusign/authenticate`).toPromise();
  }

  createEnvelope(body: any): Promise<any> {
    return this._http.post(`/api/salespro/docusign/createEnvelope`, body).toPromise();
  }

  getRecipientUrl(body: any, remote = ''): Promise<any> {
    let sUrl = `/api/salespro/docusign/getRecipientUrl`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/docusign/remote/getRecipientUrl`;
    }
    return this._http.post(sUrl, body).toPromise();
  }

  listDocuments(accountId, envelopeId, isRemote = false, guid = null): Promise<any[]> {
    let sUrl = `/api/salespro/docusign/listDocuments/${accountId}/${envelopeId}`;
    if (isRemote) {
      sUrl = `/api/salespro/docusign/remote/listDocuments/${accountId}/${envelopeId}`;
    }
    return this._http
      .get(sUrl)
      .pipe(map((result: any) => result.envelopeDocuments as any[]))
      .toPromise();
  }

  listTemplates(body: any): Promise<any> {
    return this._http.post(`/api/salespro/docusign/listTemplates`, body).toPromise();
  }

  retrieveDocument(accountId, envelopeId, documentId, isRemote = false): Promise<any> {
    let sUrl = `/api/salespro/docusign/retrieveDocument/${accountId}/${envelopeId}/${documentId}`;
    if (isRemote) {
      sUrl = `/api/salespro/docusign/remote/retrieveDocument/${accountId}/${envelopeId}/${documentId}`;
    }
    return this._http.get(sUrl, { responseType: 'text' }).toPromise();
  }

  retrieveAndUploadDocument(uploadObj: any): Promise<any> {
    const sUrl = `/api/salespro/docusign/remote/retrieveAndUploadDocument`;

    return this._http.post(sUrl, uploadObj).toPromise();
  }

  retrieveEnvelopeNotes(body: any): Promise<any> {
    return this._http.post(`/api/salespro/docusign/retrieveEnvelopeNotes`, body).toPromise();
  }

  sendEnvelope(body: any): Promise<any> {
    return this._http.post(`/api/salespro/docusign/sendEnvelope`, body).toPromise();
  }

  updateEnvelopeInfo(body: any, remote = ''): Promise<any> {
    let sUrl = `/api/salespro/docusign/updateEnvelopeInfo`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/docusign/remote/updateEnvelopeInfo`;
    }
    return this._http.post(sUrl, body).toPromise();
  }

  updateSigners(body: any): Promise<any> {
    return this._http.post(`/api/salespro/docusign/updateSigners`, body).toPromise();
  }
  salescompleteapi(guid, uid): Promise<any[]> {
    console.log('====inside salescompleteapi====guid=====', guid);
    console.log('====inside salescompleteapi====uid=====', uid);
    const surl = `/api/salespro/docusign/salescompleteapi/${guid}/${uid}`;
    return new Promise((resolve, reject) => {
      this._http.get(surl).subscribe((response: any) => {
        let values: any[] = response;
        values = values.map((item) => {
          // return new Customer(item);
        });
        resolve(values);
      }, reject);
    });
  }
  docusignLoad(body): Promise<any> {
    console.log(body);
    return this._http.post(`/api/salespro/docusign/docusignLoad`, body).toPromise();
  }
  updateEnvelopeInfoCustomer(body) {
    const sUrl = '/api/salespro/docusign/remote/updateEnvelopeInfoCustomer';
    return new Promise((resolve,reject)=>{
      this._http.post(sUrl,body).subscribe((response: any) => { 
      resolve(response);
      },reject)
    })
  }

  checkEnvelopeInfoCustomer(body): Promise<any>{
    const sUrl='/api/salespro/docusign/remote/checkEnvelopeInfoCustomer';
    return new Promise((resolve,reject)=>{
      this._http.post(sUrl,body).subscribe((response:any)=>{
        if(response)
          resolve(response);
      },reject);
    })
  }

}
