import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OdooRpcService } from '@cogito/angular-odoo';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class UsersGroupService  {
  onSearchTextChanged: Subject<any>;
  onUsersGroupChanged: BehaviorSubject<any>;

  private _usersGroup: any[];

  constructor(
    private _odoo: OdooRpcService
  ) {
    // Set the defaults
    this._usersGroup = [];
    this.onSearchTextChanged = new Subject();
    this.onUsersGroupChanged = new BehaviorSubject(this._usersGroup);
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllUsers()
      ]).then((usersGroup) => {
        this.onSearchTextChanged.subscribe(searchText => {
          const filteredUsersGroup = FuseUtils.filterArrayByString(this._usersGroup, searchText);
          this.onUsersGroupChanged.next(filteredUsersGroup);
        });
        resolve(usersGroup);
      }, reject);
    });
  }
  // Get All User Information
  getAllUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._odoo.call('res.users', 'search_read', [
        
          [
            ["groups_id","=",130]
          ],
        
      ], {
        fields:['display_name','email']
      },)
      .subscribe((response: any) => {
        this._usersGroup = response;
        this._usersGroup = this._usersGroup.map(item => {
          return {
            id: item.id,
            display_name: item.display_name,
            email: item.email,
          };
        });

        this.onUsersGroupChanged.next(this._usersGroup);
        resolve(this._usersGroup);
      }, reject);
    });
  }
}