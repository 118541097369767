import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ProductCategory } from 'app/shared/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseUtils } from '@fuse/utils';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  constructor(private _http: HttpClient) {}

  // Update Payment Log
  logDetails(updatedContent: any, remote = '') {
    return new Promise((resolve, reject) => {
      const sUrl = `/api/salespro/log`;

      return this._http.post(sUrl, updatedContent).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
}
