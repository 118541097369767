// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `content {
  position: relative;
  display: flex;
  z-index: 1;
  flex: 1 1 auto;
  width: 100%;
}
content > *:not(router-outlet) {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbnRlbnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBQTtFQUNBLGFBQUE7RUFDQSxVQUFBO0VBRUEsY0FBQTtFQUNBLFdBQUE7QUFBRjtBQUVFO0VBQ0UsYUFBQTtFQUNBLGNBQUE7RUFDQSxXQUFBO0VBQ0EsZUFBQTtBQUFKIiwiZmlsZSI6ImNvbnRlbnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJjb250ZW50IHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBkaXNwbGF5OiBmbGV4O1xuICB6LWluZGV4OiAxO1xuICAvLyBmbGV4OiAxIDAgYXV0bztcbiAgZmxleDogMSAxIGF1dG87XG4gIHdpZHRoOiAxMDAlO1xuXG4gID4gKjpub3Qocm91dGVyLW91dGxldCkge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleDogMSAwIGF1dG87XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgbWluLXdpZHRoOiAxMDAlO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/layout/components/content/content.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,UAAA;EAEA,cAAA;EACA,WAAA;AAAF;AAEE;EACE,aAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;AAAJ;AACA,wrBAAwrB","sourcesContent":["content {\n  position: relative;\n  display: flex;\n  z-index: 1;\n  // flex: 1 0 auto;\n  flex: 1 1 auto;\n  width: 100%;\n\n  > *:not(router-outlet) {\n    display: flex;\n    flex: 1 0 auto;\n    width: 100%;\n    min-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
