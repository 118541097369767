export const crmTagConfig: any = {
  status: {
    CUSTOMER_COMPLETED: 'SPRO - Complete Workflow',
    CUSTOMER_INFO_ADDED: 'SPRO - New Customer Info',
    PLAN_SELECTED: 'SPRO - Plan Selected',
    PRODUCT_SELECTED: 'SPRO - Product Selected',
    PRODUCT_REVIEWED: 'SPRO - Product Reviewed',
    REMOTE_PRODUCT_REVIEWED: 'SPRO - Product Reviewed (Remote)',
    PAYMENT_REVIEWED: 'SPRO - Payment Reviewed',
    PAYMENT_SKIPPED: 'SPRO - Payment Skipped',
    ACI_PAYMENT_DONE: 'SPRO - ACI Payment DONE',
    LATER_PAYMENT_DONE: 'SPRO - Application Completed',
    REMOTE_ACI_PAYMENT_DONE: 'SPRO - Application Completed',
    DOCUMENT_SIGNED_SALESPERSON: 'SPRO - SalesPerson Signed on Document',
    DOCUMENT_SIGNED_CUSTOMER: 'SPRO - Customer Signed on Document',
    REMOTE_DOCUMENT_SIGNED_CUSTOMER: 'SPRO - Customer Signed on Document (Remote)',
    REMOTE_FINANCE_FAILED: 'SPRO - Remote Finance Failed',
    FINANCE_FAILED: 'SPRO - Finance Failed',
    RESET_WORKFLOW: 'SPRO - Reset Workflow',
    CUSTOMER_NEW_INFO_ADDED: 'New Trim Lead',
    FINANCE_APPLICATION_PROCESSING: 'SPRO - Finance Application Processing',
    FINANCE_APPLICATION_SUBMITTED: 'SPRO - Finance Application Submitted',
    FINANCE_PENDING_CUSTOMER_ACCOUNT: 'SPRO - Finance Pending Customer Account',
    FINANCE_PENDING_FINAL_AMOUNT: 'SPRO - Finance Offer Approved',
    FINANCE_PENDING_CUSTOMER_SIGNATURE: 'SPRO - Finance Pending Customer Signature',
    FINANCE_ACCEPTED: 'SPRO - Finance Accepted',
    FINANCE_SUBMITTED: 'SPRO - Final Amount Submitted',
    REMOTE_FINANCE_APPLICATION_PROCESSING: 'SPRO - Remote Finance Application Processing',
    REMOTE_FINANCE_APPLICATION_SUBMITTED: 'SPRO - Remote Finance Application Submitted',
    REMOTE_FINANCE_PENDING_FINAL_AMOUNT: 'SPRO - Remote Finance Offer Approved',
    REMOTE_FINANCE_SUBMITTED: 'SPRO - Remote Final Amount Submitted',
    FINANCE_MANUAL_REVIEW: 'SPRO - Finance Manual Review',
    REMOTE_FINANCE_MANUAL_REVIEW: 'SPRO - Remote Finance Manual Review',
    REFUND_REQUIRED: 'Refund Required',
  },
};
