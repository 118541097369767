import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { History } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class HistoryService  {
  onHistoryChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;

  private _history: History[];

  constructor(private _http: HttpClient) {
    // Set the defaults
    this._history = [];
    this.onHistoryChanged = new BehaviorSubject(this._history);
    this.onFilterChanged = new Subject();
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getAllHistoryInfo()]).then((result) => {
        this.onFilterChanged.subscribe(({ startDate, endDate, value, searchText, searchooId }) => {
          let filteredHistory = [];
          if (searchText && searchText !== '' && !value) {
            filteredHistory = FuseUtils.filterArrayByString(this._history, searchText);
          } else if (value && value.length > 0 && !startDate && !endDate) {
            // if (value && value.length > 0) {
            filteredHistory = this._history.filter((item) => value.includes(item.action));
          } else if (startDate && endDate && value && value.length > 0) {
            filteredHistory = this._history.filter(
              ({ createdAt }) =>
                !startDate ||
                !endDate ||
                (moment(createdAt).isAfter(startDate) && moment(createdAt).isBefore(endDate)),
            );
            filteredHistory = filteredHistory.filter((item) => value.includes(item.action));
          } else {
            if (startDate && endDate) {
              filteredHistory = this._history.filter(
                ({ createdAt }) =>
                  !startDate ||
                  !endDate ||
                  (moment(createdAt).isAfter(startDate) && moment(createdAt).isBefore(endDate)),
              );
            } else {
              filteredHistory = this._history;
            }
          }
          if (searchooId && searchooId !== '') {
            filteredHistory = FuseUtils.filterArrayByString(filteredHistory, searchooId);
          }
          console.log(filteredHistory);
          this.onHistoryChanged.next(filteredHistory);
        });
        resolve(this._history);
      }, reject);
    });
  }

  // Add New History Info
  addNewHistory(history: History, remote = ''): Promise<any> {
    let sUrl = `/api/salespro/history`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/history/remote`;
    }
    return this._http.post(sUrl, history).toPromise();
  }

  getAllHistoryInfo(): Promise<History[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/history`).subscribe((response: any) => {
        this._history = response;
        console.log(this._history);
        this.onHistoryChanged.next(this._history);
        resolve(this._history);
      }, reject);
    });
  }
}
