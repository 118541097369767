import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivationEnd, NavigationStart, Event } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { navigation } from 'app/navigation/navigation';
import { AuthService } from 'app/core/auth/auth.service';
import { CustomerNewService } from 'app/core/services/customer-new.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  backUrl = null;
  navigationStarted = false;
  fuseConfig: any;
  form: UntypedFormGroup;
  private _currentCustomer = null;

  // Private
  private _unsubscribeAll: Subject<any>;

  currentUser :any;
  address = '';
  backRoute = '';
  addressBlockPages = [];
  showAddress = false;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _router: Router,
    private _customerService: CustomerNewService,
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'es',
        title: 'Spanish',
        flag: 'es',
      },
    ];

    this.addressBlockPages = ['dashboard'];

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.navigationStarted = true;
      }
      if (event instanceof ActivationEnd) {
        if (event.snapshot && this.navigationStarted) {
          const { data, params } = event.snapshot;

          this.backRoute = data.backRoute;
          this.address = '';
          if (this.addressBlockPages.indexOf(data.page) > -1) {
            this.showAddress = false;
          } else {
            this.showAddress = true;
          }
          if (data.routeName === 'plan') {
            this.formAddress(params);
          }
          switch (data.backRoute) {
            case 'plan':
              this.checkLoanApproved(params);
              break;
            case 'select':
              this.backUrl = `/customer/equipment/select/${params.guid}/${params.odooCustomerId}`;
              this.formAddress(params);
              break;
            case 'review':
              this.backUrl = `/customer/equipment/review/${params.guid}/${params.odooCustomerId}${
                data.status && data.status === 'not-approved' && '/' + data.status
              }`;
              this.formAddress(params);
              break;
            case 'payment':
              this._getFinanceStatus(params);

              break;

            case 'prefill':
              this.backUrl = `/customer/docusign/prefill/${params.guid}/${params.odooCustomerId}`;
              this.formAddress(params);
              break;

            default:
              this.backUrl = null;
              break;
          }
          this.navigationStarted = false;
        }
      }
    });
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit() {
    this._customerService.onAddressChanged.subscribe((formattedAddress) => {
      console.log(this.showAddress);
      if (
        window.location.href.indexOf('/docusign') >= 0 ||
        window.location.href.indexOf('/payment/') >= 0
      ) {
        this.showAddress = true;
      }
      this.address = formattedAddress;
    });

    this.form = this._formBuilder.group({
      colorTheme: new UntypedFormControl(),
    });
    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      // Update the stored config
      this.fuseConfig = config;

      // Set the config form values without emitting an event
      // so that we don't end up with an infinite loop
      const { colorTheme } = config;
      this.form.setValue(
        {
          colorTheme,
        },
        { emitEvent: false },
      );
    });

    // Subscribe to the form value changes
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      // Update the config
      this._fuseConfigService.config = config;
    });
    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });

    // Get the current Logged User Profile
    this._authService.currentAuth.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
      this.currentUser = value;
      console.log('Current User', this.currentUser);
    });
  }
  async formAddress(params) {
    /*this._currentCustomer = await this._customerService.getCustomerByGuid(params.guid);
    // this.address = this._currentCustomer.display_name;*/
  }
  async _getFinanceStatus(params) {
    const customer = await this._customerService.getCustomerByGuid(params.guid);

    let paymentType = 'cash';
    let paymentStatus;
    if (customer && customer.lendProAccountStatus) {
      paymentType = 'finance';
      if (customer.lendProAccountStatus === 'approved') {
        paymentStatus = 'approved';
      } else {
        paymentStatus = 'not-approved';
      }
    }
    if (
      customer &&
      customer.isRemote &&
      customer.remotePaymentType === 'finance' &&
      params.type !== 'customer'
    ) {
      this.backUrl = `/customer/equipment/review/${params.guid}/${params.odooCustomerId}${
        paymentStatus === 'not-approved' && '/' + paymentStatus
      }`;
    } else if (
      customer &&
      params.type !== 'customer' &&
      (customer.isSkipped ||
        (customer.isRemote &&
          (customer.remotePaymentType === 'homeProFinance' ||
            customer.remotePaymentType === 'cash')))
    ) {
      this.backUrl = `/customer/payment/${paymentType}/${params.guid}/${params.odooCustomerId}${
        paymentStatus && '/' + paymentStatus
      }`;
    } else {
      this.backUrl = null;
    }

    // this.address = customer.display_name;
  }

  async isShowRoomUser(params) {
    this._currentCustomer = await this._customerService.getCustomerByGuid(params.guid);

    return this._currentCustomer.isShowroom;
  }
  async checkLoanApproved(params) {
    this._currentCustomer = await this._customerService.getCustomerByGuid(params.guid);
    if (
      this._currentCustomer &&
      this._currentCustomer.lendProAccountStatus &&
      this._currentCustomer.lendProAccountStatus === 'approved'
    ) {
      this.backUrl = null;
    } else {
      this.backUrl = `/customer/plan/${params.guid}/${params.odooCustomerId}`;
    }
    // this.address = this._currentCustomer.display_name;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  // Toolbar Logout Menu Handler
  async toolbarLogoutHandler() {
    await this._authService.logout();
  }
}
