import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Permissions } from 'app/shared/models/permissions.model'


@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
    onPermissionsChanged: BehaviorSubject<any>;
    explicitPackages:[];
    private _permissions: Permissions[];
  constructor(private _http: HttpClient) 
  {
    this._permissions = [];
    this.onPermissionsChanged = new BehaviorSubject(this._permissions);
   }

   resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.listPermissions(),this.getAllExplicitPackages()]).then((result) => {
        console.log(result); 
        resolve(this._permissions);
      }, reject);
    });
  }

  // Add New Permissions Info
  addNewPermissions(newPermissions: Permissions): Promise<any> {
    return new Promise((resolve, reject) => {
     return this._http.post(`/api/salespro/allpermissions`, newPermissions).subscribe((response: any) => {
     this._permissions.push(response);
     console.log(response);
     this.onPermissionsChanged.next(this._permissions)
     resolve(response);
      }, reject);
    });
  }


  // Get All Permissions Info
  listPermissions(): Promise<Permissions[]> {
    return new Promise((resolve, reject) => {
      return this._http.get(`/api/salespro/allpermissions`).subscribe((response: any) => {
       let userGroups=[];
        response.map(data=>{
          if(!userGroups.find(({v0}) => v0 === data.v0)){
          const allRoles =
          response
          .filter((item)=> item?.v0 === data?.v0)
          .map((item)=>item.group_name);
          const allDescription =
            response
            .filter((item)=> item?.v0 === data?.v0)
            .map((item)=>item.description);
            userGroups.push({
              v0 : data.v0, 
              user_roles: allRoles,
              action : data.action,
              userId:data.userId,
              description: allDescription
            });
          }
        });
        this._permissions =userGroups;
        this._permissions = this._permissions.map((item) => {
          new Permissions(item);
          return new Permissions(item);
        });
        this.onPermissionsChanged.next(this._permissions)
        this._permissions;
        
        resolve(this._permissions);
      }, reject);
    });
  }

  getAllExplicitPackages():Promise<any>{
    return new Promise((resolve,reject)=>{
      return this._http.get('/api/salespro/allpermissions/usersgroup/allexplicitpackages').subscribe((response:any)=>{
        console.log(response);
        this.explicitPackages = response;
        resolve(response);
      },reject);
    })
  }

  listUsersExplicitPackages(userId): Promise<Permissions[]> {
    return new Promise((resolve, reject) => {
      if(userId == ''){
       return resolve([]);
      }
      return this._http.get(`/api/salespro/allpermissions/usersgroup/explicitpackage/${userId}`).subscribe((response: any) => {
      console.log("hereeeee",response);
      const userExplicitPackage = response.map(item =>{
        return {...item,guid : (item.guid).toString()}
      });
      resolve(userExplicitPackage);
      }, reject);
    });
  }

  getExplicitPackagesFromLocal(){
    return  this.explicitPackages.map((item:any) => {
      return {...item,guid: (item.guid).toString()}
    });
  }

  // Remove the Selected Permissions Info
  removePermissions(selectedPermissions: Permissions): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .delete(`/api/salespro/allpermissions/${selectedPermissions.userId}`)
        .subscribe((response: any) => {
          const PermissionsIndex = this._permissions.findIndex((object)=>object.v0 === selectedPermissions.v0);
          this._permissions.splice(PermissionsIndex, 1);
          this.onPermissionsChanged.next(this._permissions);
          resolve(response);
        }, reject);
    });
  }
  updatePermissions(oldPermissions: any, newPermissions:any):Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('updatereee',oldPermissions, newPermissions);
      this._http
        .patch(`/api/salespro/allpermissions`, newPermissions)
        .subscribe(async(response: any) => {
          this._permissions;
          let userId = newPermissions.userId;
          if(oldPermissions.userId === '' ||newPermissions.userId === ''){
            const currentPermission = response.find((item)=>item.v0 === newPermissions.v0)
            userId = currentPermission.userId;
          }
          const PermissionsIndex = this._permissions.findIndex(object => {
            return object.userId === userId;
          });
          const description = response.filter((item)=> item.userId=== userId).map(item=> item.description);;
          const updatedPermisison = new  Permissions({
            action : 'allow',
            userId : userId,
            description,
            user_roles : newPermissions.roles,
            v0 : oldPermissions.email 
          });
          if((PermissionsIndex < 0)){
            this._permissions.push(updatedPermisison);
          }
          else{
            this._permissions[PermissionsIndex].update(updatedPermisison);
          }
          console.log(this._permissions);
          this.onPermissionsChanged.next(this._permissions);
          console.log(response);
          resolve(response)
        }, reject);
    });
  }
}
