import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'salespro-cache-refresh-modal',
  templateUrl: './cache-refresh-modal.component.html',
  styleUrls: ['./cache-refresh-modal.component.scss']
})
export class CacheRefreshModalComponent implements OnInit {
  versionNumber:string;
  constructor(
    public matDialogRef : MatDialogRef<CacheRefreshModalComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) { 
    this.versionNumber = _data.version;
  }

  ngOnInit(): void {
  }

  onRefresh(){
    window.location.reload();
  }

}
