import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import * as xml2js from 'xml2js';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'app/core/services/configuration.service';
import Bugsnag from '@bugsnag/js';
import { PaymentLogService } from 'app/core/services/payment-log.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { paymentPdfConfig } from 'app/configs/payment.pdf';
import { waitForAsync } from '@angular/core/testing';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class AciService {
  private _fileBlob: any;
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _configurationService: ConfigurationService,
    private _paymentLogService: PaymentLogService,
  ) {}

  async addFunding(
    paymentType = '',
    paymentStatus = '',
    billingAccountNumber,
    customerGuid,
    odooCustomerId,
    remote = '',
  ) {
    console.log('==remote=....=', remote);
    const aciConfigData = await this._configurationService.getConfiguration('aci', remote);
    const domain = window.location.href.replace(this._router.url, '');
    // tslint:disable-next-line: max-line-length
    let returnUrl = `${domain}/customer/payment/checkout/${paymentType}/${billingAccountNumber}/${customerGuid}/${odooCustomerId}${
      paymentStatus && '/' + paymentStatus
    }`;
    let timeOutUrl = `${domain}/customer/payment/checkout/timeout/${paymentType}/${billingAccountNumber}/${customerGuid}/${odooCustomerId}${
      paymentStatus && '/' + paymentStatus
    }`;
    if (remote === 'remote') {
      /* if (paymentType === 'finance') {*/
      returnUrl = `${domain}/auth/remote/payment/checkout/${paymentType}/${billingAccountNumber}/${customerGuid}/${odooCustomerId}`;
      timeOutUrl = `${domain}/auth/remote/payment/checkout/timeout`;
      timeOutUrl = `${timeOutUrl}/${paymentType}/${billingAccountNumber}/${customerGuid}/${odooCustomerId}`;

      /*} else {
        returnUrl = `${domain}/auth/remote/payment/checkout/${paymentType}/${billingAccountNumber}/${customerGuid}/${odooCustomerId}`;
      }*/
    }

    const xmlBody = `
      <?xml version='1.0' encoding='UTF-16'?>
      <add-funding-request
        xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance'
        xmlns='http://www.princetonecom.com/fundingPortal/addfundingrequest'
        xsi:schemaLocation='http://www.princetonecom.com/fundingPortal/addfundingrequest add_funding_request.xsd'>
        <identity>
          <business-id>${aciConfigData['aci_id']}</business-id>
          <login>${aciConfigData['aci_name']}</login>
          <password>${aciConfigData['aci_data']}</password>
        </identity>
        <billing-account-number>${billingAccountNumber}</billing-account-number>
        <success-url>${returnUrl}</success-url>
        <failure-url>${returnUrl}</failure-url>
        <timeOut-url>${timeOutUrl}</timeOut-url>
      </add-funding-request>
    `

      .replace(/  /g, '')
      .replace(/\+/gi, '%2B')
      .replace(/\n/g, ' ')
      .replace(/> /g, '>')
      .replace(/ </g, '<')
      .trim();

    const body = new HttpParams().set('xml', xmlBody);
    Bugsnag.leaveBreadcrumb('Payment Request', { xmlRequest: xmlBody });
    Bugsnag.notify('Payment Request logged');
    return this._http
      .post('/api/aci/addFunding.do', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        responseType: 'text',
      })
      .pipe(
        map(async (item) => {
          // debugger;
          const parser = new xml2js.Parser();
          const result = await parser.parseStringPromise(item);
          const paymentData = await this._paymentLogService.logPaymentDetails(
            {
              odooId: odooCustomerId,
              paymentApiRequest: xmlBody,
              paymentResult: JSON.stringify(result),
            },
            remote,
          );
          return result;
        }),
      )
      .toPromise();
  }

  async makePayment(customer, paymentInfo, remote = '') {
    let fullName = `${customer.lastName}`;
    fullName = fullName.replace(/[^a-zA-Z0-9]/g, '');
    // let streetAddress = `${customer.streetNumber} ${customer.streetName} ${customer.city}`;
    let streetAddress = `${customer.streetName} ${customer.city}`;
    streetAddress = streetAddress.replace(/[^a-zA-Z0-9]/g, '');
    streetAddress = streetAddress.trimLeft();
    const address = `${customer.odooId} - ${fullName},${streetAddress} , ${customer.state},${customer.zipCode}`;
    /* New ACI ADDRESS SPILIT UP--*/
    const aciOdooId = customer.odooId;
    const stateZipCombined = `${customer.state},${customer.zipCode}`;
    /* New ACI ADDRESS SPILIT UP--*/

    const aciConfigData = await this._configurationService.getConfiguration('aci', remote);
    // debugger;
    const xmlBody = `
      <?xml version="1.0" encoding="UTF-16"?>
      <make-payment-request
        xmlns="http://www.princetonecom.com/fundingPortal/makepaymentrequest"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <identity>
          <business-id>${aciConfigData['aci_id']}</business-id>
          <login>${aciConfigData['aci_name']}</login>
          <password>${aciConfigData['aci_data']}</password>
        </identity>

        <funding-token-id>${paymentInfo.tokenId}</funding-token-id>
        <ecommerce-indicator>ECOMMERCE</ecommerce-indicator>
        <nacha-standard-entry-class>WEB</nacha-standard-entry-class>
        <credit-debit-indicator>DEBIT</credit-debit-indicator>
        <requested-payment-date>${moment().utc().format('YYYY-MM-DD')}</requested-payment-date>
        <remittance>
          <billing-account>
            <billing-account-number>${paymentInfo.billingAccountNumber}</billing-account-number>
          </billing-account>
          <remit-amount>${paymentInfo.totalCost}</remit-amount>
          <remit-fee>0</remit-fee>
          <fee-waiver-reason/>
          <payment-remit-field><value>${aciOdooId}</value></payment-remit-field>
          <payment-remit-field><value>${fullName}</value></payment-remit-field>
          <payment-remit-field><value>${streetAddress}</value></payment-remit-field>
          <payment-remit-field><value>${stateZipCombined}</value></payment-remit-field>
        </remittance>
        <product>CONNECT_WEB</product>
        <email-address>${customer.email}</email-address>
      </make-payment-request>
    `
      .replace(/  /g, '')
      .replace(/\+/gi, '%2B')
      .replace(/\n/g, ' ')
      .trim();

    const body = new HttpParams().set('xml', xmlBody);

    return this._http
      .post('/api/aci/makePayment.do', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        responseType: 'text',
      })
      .pipe(
        map(async (item) => {
          // debugger;
          const parser = new xml2js.Parser();
          const result = await parser.parseStringPromise(item);
          const paymentData = await this._paymentLogService.logPaymentDetails(
            {
              odooId: customer.odooId,
              paymentApiRequest: xmlBody,
              tokenId: paymentInfo.tokenId,
              status: paymentInfo.status,
              paymentResult: JSON.stringify(result),
            },
            remote,
          );
          let paymentInfoArray = [];
          if (customer.paymentResultInfo) {
            paymentInfoArray = JSON.parse(customer.paymentResultInfo);
          }
          const paymentResultObj = {
            billingAccountNumber: paymentInfo.billingAccountNumber,
            paymentResult: result,
          };
          paymentInfoArray.push(paymentResultObj);
          await this._paymentLogService.updateCustomer(
            customer.customerGuid,
            {
              billingAccountNumber: paymentInfo.billingAccountNumber,
              paymentResultInfo: JSON.stringify(paymentInfoArray),
            },
            remote,
          );
          return result;
        }),
      )
      .toPromise();
  }

  async getFundingStatus(customer, billingAccountNumber, remote = '') {
    const aciConfigData = await this._configurationService.getConfiguration('aci',remote);

    /*  const xmlBody = `<?xml version="1.0" encoding="UTF-8"?><search-funding-token-request
          xmlns="http://www.princetonecom.com/fundingPortal/searchfundingtokenreques
          t4" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <identity>
            <businessid>${aciConfigData['aci_id']}</business-id>
            <login>${aciConfigData['aci_name']}</login>
          <password>${aciConfigData['aci_data']}</password>
            </identity>
          <billing-account-number>1632394285066</billing-account-number>
          </search-funding-token-request>`

      .replace(/  /g, '')
      .replace(/\+/gi, '%2B')
      .replace(/\n/g, ' ')
      .trim();

    const body = new HttpParams().set('xml', xmlBody);
    return this._http
      .post('/api/aci/searchFundingToken4.do', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        responseType: 'text',
      })
      .pipe(
        map(async (item) => {
          // debugger;
          const parser = new xml2js.Parser();
          const result = await parser.parseStringPromise(item);

          return result;
        }),
      )
      .toPromise();
*/
    const xmlBody = `<?xml version="1.0" encoding="UTF-16"?>
<search-payment-by-billing-account-request
xmlns="http://www.princetonecom.com/connect/searchpaymentbybillingaccountrequest1"
xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
<identity>
<business-id>${aciConfigData['aci_id']}</business-id>
<login>${aciConfigData['aci_name']}</login>
<password>${aciConfigData['aci_data']}</password>
</identity>
<billing-account-number>${billingAccountNumber}</billing-account-number>
<payment-date-from>${moment().utc().subtract(1, 'months').format('YYYY-MM-DD')}</payment-date-from>
<payment-date-to>${moment().utc().format('YYYY-MM-DD')}</payment-date-to>
</search-payment-by-billing-account-request>`

      .replace(/  /g, '')
      .replace(/\+/gi, '%2B')
      .replace(/\n/g, ' ')
      .trim();

    const body = new HttpParams().set('xml', xmlBody);
    return this._http
      .post('/api/searchtoken/searchPaymentByBillingAccount1.do', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        responseType: 'text',
      })
      .pipe(
        map(async (item) => {
          // debugger;
          const parser = new xml2js.Parser();
          const result = await parser.parseStringPromise(item);
          const paymentData = await this._paymentLogService.logPaymentDetails(
            {
              odooId: customer.odooId,
              paymentApiRequest: xmlBody,
              tokenId: customer.tokenId,
              status: customer.status,
              paymentResult: JSON.stringify(result),
            },
            remote,
          );
          return result;
        }),
      )
      .toPromise();
  }
}
