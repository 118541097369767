import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';

export let navigation: FuseNavigation[] = [
  {
    id: 'admin-dashboard',
    title: 'Dashboard',
    translate: 'NAV.ADMIN.DASHBOARD',
    type: 'item',
    icon: 'dashboard',
    url: '/admin/dashboard',
  },
  {
    id: 'admin-admin-settings',
    title: 'Admin Settings',
    translate: 'NAV.ADMIN.ADMIN_SETTINGS',
    type: 'group',
    icon: 'change_history',

    children: [
      {
        id: 'admin-products',
        title: 'Products',
        translate: 'NAV.ADMIN.PRODUCTS',
        type: 'item',
        icon: 'shopping_cart',
        url: '/admin/products',
      },
      {
        id: 'admin-packages',
        title: 'Packages',
        translate: 'NAV.ADMIN.PACKAGES',
        type: 'item',
        icon: 'local_offer',
        url: '/admin/packages',
      },
      /*{
        id: 'admin-users',
        title: 'Users',
        translate: 'NAV.ADMIN.USERS',
        type: 'item',
        icon: 'change_history',
        url: '/admin/users',
      },*/
      {
        id: 'admin-branches',
        title: 'Branches',
        translate: 'NAV.ADMIN.BRANCHES',
        type: 'item',
        icon: 'account_balance',
        url: '/admin/branches',
      },
      {
        id: 'admin-history',
        title: 'History',
        translate: 'NAV.ADMIN.HISTORY',
        type: 'item',
        icon: 'history',
        url: '/admin/history',
      },
      {
        id: 'admin-product-categories',
        title: 'Product Category',
        translate: 'NAV.ADMIN.PRODUCT_CATEGORIES',
        type: 'item',
        icon: 'view_list',
        url: '/admin/productCategories',
      },
      {
        id: 'admin-sales-statistics',
        title: 'Sales Statistics',
        translate: 'NAV.ADMIN.SALES_STATISTICS',
        type: 'item',
        icon: 'trending_up',
        url: '/admin/sales',
      },
      {
        id: 'admin-reset-lead',
        title: 'Reset Lead',
        translate: 'NAV.ADMIN.RESET_LEAD',
        type: 'item',
        icon: 'autorenew',
        url: '/admin/resetLead',
      },
      {
        id: 'admin-builders',
        title: 'Builders',
        translate: 'NAV.ADMIN.BUILDERS',
        type: 'item',
        icon: 'people',
        url: '/admin/builders',
      },
      {
        id: 'admin-associations',
        title: 'Associations',
        translate: 'NAV.ADMIN.ASSOCIATION',
        type: 'item',
        icon: 'group_add',
        url: '/admin/associations',
      },
      {
        id: 'admin-permissions',
        title: 'Permissions',
        translate: 'NAV.ADMIN.PERMISSIONS',
        type: 'collapsable',
        icon: 'vpn_key',
        
        children: [
          {
            id: 'admin-user-group',
            title: 'Users Group',
            translate: 'NAV.ADMIN.USERGROUP',
            type: 'item',
            icon: 'person_outline',
            url: '/admin/usersgroup',
          },
          {
            id: 'permissions-feature-matrix',
            title: 'Feature Matrix',
            translate: 'NAV.ADMIN.FEATUREMATRIX',
            type: 'item',
            icon: 'apps',
            url: '/admin/featurematrix',
          },
          {
            id: 'permissions-package-group',
            title: 'Package Group',
            translate: 'NAV.ADMIN.PACKAGEGROUP',
            type: 'item',
            icon: 'playlist_add_check',
            url: '/admin/packagegroup',
          },
         ]
      },
    ],
  },
];
if (!environment.SYNC_ASSOCIATION) {
  navigation[1].children = navigation[1].children.filter((item) => item.id !== 'admin-associations');
}
