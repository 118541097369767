export const locale = {
  lang: 'en',
  data: {
    // Main Navigation
    NAV: {
      ADMIN: {
        DASHBOARD: 'Dashboard',
        ADMIN_SETTINGS: 'Admin Settings',
        PRODUCTS: 'Products',
        PACKAGES: 'Packages',
        USERS: 'Users',
        BRANCHES: 'Branches',
        HISTORY: 'History',
        PRODUCT_CATEGORIES: 'Product Categories',
        SALES_STATISTICS: 'Sales Statistics',
        RESET_LEAD: 'Reset Lead',
        BUILDERS: 'Builders',
        ASSOCIATION: 'Associations',
        PERMISSIONGROUP: 'Permission Group',
        PERMISSIONS: 'Permissions',
        USERGROUP: 'User Group',
        FEATUREMATRIX: 'Feature Matrix',
        PACKAGEGROUP: 'Package Group'
      },
      MANAGER: {
        DASHBOARD: 'Dashboard',
      },
      USER: {
        DASHBOARD: 'Dashboard',
      },
      APPLICATIONS: 'Applications',
      CONTACTS: 'Contacts',
      DASHBOARDS: 'Dashboards',
      HIGHLIGHTED_OPTION: 'Highlighted Option',
    },
    // Auth Module
    AUTH: {
      LOGIN: {
        TITLE: 'LOGIN TO YOUR ACCOUNT',
      },
      REGISTER: {
        TITLE: 'CREATE AN ACCOUNT',
      },
    },
  },
};
