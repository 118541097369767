export class Video {
  id?: number;
  text: string;
  yes?: number;
  no?: number;
  createdAt?: string;
  updatedAt?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.text !== undefined) {
      this.text = item.text;
    }
    if (item.yes !== undefined) {
      this.yes = item.yes;
    }
    if (item.no !== undefined) {
      this.no = item.no;
    }
    if (item.createdAt !== undefined) {
      this.createdAt = item.createdAt;
    }
    if (item.updatedAt !== undefined) {
      this.updatedAt = item.updatedAt;
    }
  }
}
