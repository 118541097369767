import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ProductCategory } from 'app/shared/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class ProductCategoryService  {
  filterBy: string;
  onFilterChanged: Subject<any>;
  private _categories: ProductCategory[];
  onProductCategoryChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;

  constructor(private _http: HttpClient) {
    // Set the defaults
    this.onFilterChanged = new Subject();
    this._categories = [];
    this.onProductCategoryChanged = new BehaviorSubject(this._categories);
    this.onSearchTextChanged = new Subject();
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getAllProductCategories()]).then((result) => {
        this.onFilterChanged.subscribe((filter) => {
          this.filterBy = filter;
          if (this.filterBy === 'all') {
            this.onProductCategoryChanged.next(this._categories);
            return;
          }
          /*    const filteredProducts = this._categories.filter((product) => {
            return product.packages && product.packages.includes(this.filterBy);
          })*/
          const filteredProducts = [];
          this.onProductCategoryChanged.next(filteredProducts);
        });

        this.onSearchTextChanged.subscribe((searchText) => {
          const searchProducts = FuseUtils.filterArrayByString(this._categories, searchText);
          this.onProductCategoryChanged.next(searchProducts);
        });

        resolve(this._categories);
      }, reject);
    });
  }

  // Add New Product Info
  addNewProductCategory(newProductCategory: ProductCategory): Promise<any> {
    return new Promise((resolve, reject) => {
      return this._http
        .post(`/api/salespro/productcategory`, newProductCategory)
        .subscribe((response: any) => {
          const resultProductCategory = new ProductCategory(response);
          this._categories.push(resultProductCategory);

          this.onProductCategoryChanged.next(this.orderByOrder(this._categories));
          resolve(resultProductCategory);
        }, reject);
    });
  }
  orderByOrder(_categories): Array<any> {
    if (_categories && _categories.length > 0) {
      return _categories.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    } else {
      return [];
    }
  }
  // Get All Products Infor
  getAllProductCategories(): Promise<ProductCategory[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/productcategory`).subscribe((response: any) => {
        this._categories = response;

        this._categories = this._categories.map((item) => {
          return new ProductCategory(item);
        });

        this.onProductCategoryChanged.next(this._categories);
        resolve(this._categories);
      });
    });
  }

  // Remove the Selected Product Info
  removeProductCategory(selectedProduct: ProductCategory): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .delete(`/api/salespro/productcategory/${selectedProduct.guid}`)
        .subscribe((response: any) => {
          const productCategoryIndex = this._categories.indexOf(selectedProduct);
          this._categories.splice(productCategoryIndex, 1);
          this.onProductCategoryChanged.next(this.orderByOrder(this._categories));
          resolve(response);
        }, reject);
    });
  }

  // Update the Selected Product Info
  updateProductCategory(oldCategory: ProductCategory, newProductCategory: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .patch(`/api/salespro/productcategory/${oldCategory.guid}`, newProductCategory)
        .subscribe((response: any) => {
          const productCategoryIndex = this._categories.indexOf(oldCategory);
          oldCategory.update(newProductCategory);
          this._categories[productCategoryIndex].update(oldCategory);
          this.onProductCategoryChanged.next(this.orderByOrder(this._categories));
          resolve(response);
        }, reject);
    });
  }
}
