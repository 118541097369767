export class Branch {
  id?: number;
  guid?: string;
  name: string;
  address: string;
  unit?: string;
  city: string;
  state: string;
  zipCode: string;
  supervisor: string;
  phone: string;
  packages?: string;
  createdAt?: string;
  updatedAt?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.name !== undefined) {
      this.name = item.name;
    }
    if (item.address !== undefined) {
      this.address = item.address;
    }
    if (item.unit !== undefined) {
      this.unit = item.unit;
    }
    if (item.city !== undefined) {
      this.city = item.city;
    }
    if (item.state !== undefined) {
      this.state = item.state;
    }
    if (item.zipCode !== undefined) {
      this.zipCode = item.zipCode;
    }
    if (item.supervisor !== undefined) {
      this.supervisor = item.supervisor;
    }
    if (item.phone !== undefined) {
      this.phone = item.phone;
    }
    if (item.packages !== undefined) {
      this.packages = item.packages;
    }
    if (item.createdAt !== undefined) {
      this.createdAt = item.createdAt;
    }
    if (item.updatedAt !== undefined) {
      this.updatedAt = item.updatedAt;
    }
  }
}
