export const crmDevStageConfig: any = {
  LEAD_GENERATION: {
    NEW_TRIM_LEADS: { id: 46 },
    BRANDING_AVAILABLE: { id: 139 },
    BRANDING_SOLD_SIGN: { id: 140 },
    PROSPECTING: { id: 141 },
    APPOINTMENT_SET: { id: 59 },
    PRESENTATION_GIVEN: { id: 69 },
    WON: { id: 78 },
    LOST: { id: 113 },
    FIELD_REJECTED: { id: 67 },
  },
  ONIT_HOME: {
    WON: { id: 206 },
  }
};
