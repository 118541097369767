export class CustomerStar {
  odooId: number;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (item.odooId !== undefined) {
      this.odooId = item.odooId;
    }
  }
}
