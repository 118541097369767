import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OdooRpcService } from '@cogito/angular-odoo';
import { float } from 'aws-sdk/clients/lightsail';

@Injectable()
export class CrmTagService {
  constructor(private _http: HttpClient, private _odoo: OdooRpcService) {}

  // Add new Crm Stage Info
  addNewCrmTag(name: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this._odoo
        .call(
          'crm.lead.tag',
          'create',
          [
            {
              name,
            },
          ],
          {},
        )
        .subscribe(async (newTagId: number) => {
          resolve(newTagId);
        }, reject);
    });
  }

  // Get Crm Stage Info
  getAllCrmTags(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._odoo
        .call('crm.lead.tag', 'search_read', [], {
          fields: ['name'],
          order: 'write_date DESC',
        })
        .subscribe((response: any) => {
          if (response && response.length > 0) {
            resolve(response);
          } else {
            resolve([]);
          }
        }, reject);
    });
  }
}
