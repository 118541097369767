import { Component } from '@angular/core';

@Component({
  selector: 'fuse-demo-sidebar',
  templateUrl: './demo-sidebar.component.html',
  styleUrls: [
    './demo-sidebar.component.scss'
  ]
})
export class FuseDemoSidebarComponent {
  /**
   * Constructor
   */
  constructor() {}
}
