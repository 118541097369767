import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import * as xml2js from 'xml2js';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigurationService {
  constructor(private _http: HttpClient, private _router: Router) {}

  getConfiguration(configType: string, remote = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      let sUrl = `/api/salespro/config/${configType}`;
      if (remote === 'remote') {
        sUrl = `/api/salespro/config/remote/${configType}`;
      }
      this._http.get(sUrl).subscribe((response: any) => {
        console.log(response);
        const config = response;
        return resolve(config);
      }, reject);
    });
  }
}
