import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserBranch } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserBranchService  {
  onUserBranchChanged: BehaviorSubject<any>;

  private _userBranches: UserBranch[];

  constructor(
    private _http: HttpClient
  ) {
    // Set the defaults
    this._userBranches = [];
    this.onUserBranchChanged = new BehaviorSubject(this._userBranches);
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllUserBranches()
      ]).then((result) => {
        resolve(this._userBranches);
      }, reject);
    });
  }

  // Add New UserBranch Info
  addNewUserBranch(newUserBranch: UserBranch): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(`/api/salespro/user-branches`, newUserBranch).subscribe((response: any) => {
        this._userBranches.push(response);
        this.onUserBranchChanged.next(this._userBranches);
        resolve(response);
      }, reject);
    });
  }

  // Get UserBranch Info by UserId
  getUserBranchByUserId(userId: string): Promise<UserBranch> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/user-branches/${userId}`).subscribe((response: any) => {
        const userBranch: UserBranch = new UserBranch(response);
        resolve(userBranch);
      }, reject);
    });
  }

  // Get All UserBranch Info
  getAllUserBranches(): Promise<UserBranch[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/user-branches`).subscribe((response: any) => {
        this._userBranches = response;

        this._userBranches = this._userBranches.map(item => {
          return new UserBranch(item);
        });

        this.onUserBranchChanged.next(this._userBranches);
        resolve(this._userBranches);
      }, reject);
    });
  }

  // Remove the Selected UserBranch Info
  removeUserBranch(selectedUserBranch: UserBranch): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.delete(`/api/salespro/user-branches/${selectedUserBranch.userId}`).subscribe((response: any) => {
        const userBranchIndex = this._userBranches.indexOf(selectedUserBranch);
        this._userBranches.splice(userBranchIndex, 1);
        this.onUserBranchChanged.next(this._userBranches);
        resolve(response);
      }, reject);
    });
  }

  // Update the Selected UserBranch Info
  updateUserBranch(oldUserBranch: UserBranch, newUserBranch: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.patch(`/api/salespro/user-branches/${oldUserBranch.userId}`, newUserBranch).subscribe((response: any) => {
        const userBranchIndex = this._userBranches.indexOf(oldUserBranch);
        oldUserBranch.update(newUserBranch);
        this._userBranches[userBranchIndex].update(oldUserBranch);
        this.onUserBranchChanged.next(this._userBranches);
        resolve(response);
      }, reject);
    });
  }
}
