import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SendgridService {
  constructor(private _http: HttpClient) {}
  sendQuoteMail(body: any): Promise<any> {
    return this._http.post(`/api/salespro/sendgrid/sendQuoteMail`, body).toPromise();
  }
  sendCustomerSignUrl(body: any): Promise<any> {
    return this._http.post(`/api/salespro/sendgrid/sendCustomerSignUrl`, body).toPromise();
  }
  sendPaymentSuccessMail(body: any): Promise<any> {
    return this._http.post(`/api/salespro/sendgrid/sendPaymentSuccessMail`, body).toPromise();
  }

  sendTermsAndConditionsMail(body:any): Promise<any>{
    console.log('service ',body);
    
    return this._http.post('/api/salespro/sendgrid/sendTermsAndConditionsMail',body).toPromise();
  }

  sendCustomerDocumentSuccessMail(body: any): Promise<any> {
    return this._http
      .post(`/api/salespro/sendgrid/sendCustomerDocumentSuccessMail`, body)
      .toPromise();
  }
  sendCompleteMail(body: any, remote = ''): Promise<any> {
    let apiUrl = `/api/salespro/sendgrid/sendCompleteMail`;
    if (remote === 'remote') {
      apiUrl = `/api/salespro/sendgrid/remote/sendCompleteMail`;
    }
    return this._http.post(apiUrl, body).toPromise();
  }

  sendLendProMail(body: any): Promise<any> {
    return this._http.post(`/api/salespro/sendgrid/remote/sendLendProMail`, body).toPromise();
  }
  sendSignCompleteMail(body: any): Promise<any> {
    return this._http.post(`/api/salespro/sendgrid/sendSignCompleteMail`, body).toPromise();
  }
}
