import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'manager-dashboard',
    title: 'Dashboard',
    translate: 'NAV.MANAGER.DASHBOARD',
    type: 'item',
    icon: 'email',
    url: '/manager/dashboard'
  }
];
