import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Product } from 'app/shared/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class ProductService  {
  filterBy: string;
  onFilterChanged: Subject<any>;
  private _products: Product[];
  onProductChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;
  onProductPagination: BehaviorSubject<any>;
  productsCount: any;
  filter: string;
  offset = 0;
  isFirstFilter = false;
  private _allProducts: Product[];
  page = '';
  onFilterByChanged: Subject<string>;
  constructor(private _http: HttpClient) {
    // Set the defaults
    this.onFilterChanged = new Subject();
    this._products = [];
    this._allProducts = [];
    this.onProductChanged = new BehaviorSubject(this._products);
    this.onProductPagination = new BehaviorSubject(this._products);
    this.onSearchTextChanged = new Subject();
    this.onFilterByChanged = new BehaviorSubject('all');
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getAllProducts()]).then((result) => {
        this.onFilterChanged.subscribe((filterData) => {
          const { filter, page } = filterData;
          this.filter = filter;
          this.page = page;
          if (this.page === '' && this.filter) {
            this.onFilterByChanged.next(this.filter);
          }
          /*if (page === '' && filter) {
            this.getAllProductsData(10, 0, filter, '', '');
          } else if (page === 'builder' && filter) {
            this.getAllProductsData(10, 0, filter, '', page);
          }*/
          /*  this.filterBy = filter;
          if (this.filterBy === 'all') {
            this.onProductChanged.next(this._products);
            return;
          }
          const filteredProducts = this._products.filter((product) => {
            return product.packages && product.packages.includes(this.filterBy);
          });
          this.onProductChanged.next(filteredProducts);*/
        });
        /*this.onFilterChanged.subscribe((filter) => {
          this.filterBy = filter;
          if (this.filterBy === 'all') {
            this.onProductChanged.next(this._products);
            return;
          }
          const filteredProducts = this._products.filter((product) => {
            return product.packages && product.packages.includes(this.filterBy);
          });
          this.onProductChanged.next(filteredProducts);
        });
        this.onSearchTextChanged.subscribe((searchText) => {
          const searchProducts = FuseUtils.filterArrayByString(this._products, searchText);
          this.onProductChanged.next(searchProducts);
        });*/

        this._allProducts = result[0];
        if (this._allProducts.length > 0) {
          this._allProducts = this._allProducts.map((item) => {
            return new Product(item);
          });

          // this.onProductChanged.next(this._products);
        }

        resolve(this._allProducts);
      }, reject);
    });
  }

  // Add New Product Info
  addNewProduct(newProduct: Product): Promise<any> {
    return new Promise((resolve, reject) => {
      return this._http.post(`/api/salespro/products`, newProduct).subscribe((response: any) => {
        const resultProduct = new Product(response);
        this._products['rows'].push(resultProduct);
        this.onProductPagination.next(this._products);
        resolve(resultProduct);
      }, reject);
    });
  }

  // Get All Products Infor
  getAllProducts(): Promise<Product[]> {
    console.log('===inside all....');
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/products`).subscribe((response: any) => {
        this._products = response;

        this._products = this._products.map((item) => {
          return new Product(item);
        });

        this.onProductChanged.next(this._products);
        resolve(this._products);
      });
    });
  }
  // Get All Products Infor
  getAllProductsData(
    limit: number,
    offset: number,
    filter: string,
    searchText: string,
    page: string,
  ) {
    let url = `/api/salespro/products/${filter}/${limit}/${offset}`;
    if (searchText.length > 0) {
      url = `/api/salespro/products/${filter}/${searchText}/${limit}/${offset}`;
    }
    if (page === 'builder' && (filter || searchText)) {
      url = `/api/salespro/products/filterby/${page}/${filter}/${limit}/${offset}`;
      if (searchText.length > 0) {
        url = `/api/salespro/products/filterby/${page}/${filter}/${searchText}/${limit}/${offset}`;
      }
    }
    this.filter = filter;
    this.offset = offset;
    this.page = page;
    this._http.get(url).subscribe((response: any) => {
      this._products = response;
      this.productsCount = response.count;
      this.onProductPagination.next(this._products);
    });
    return this.onProductPagination;
  }

  /*------------------SEARCH BOX MODIFCATIONS START******************************/
  getSearchProducts(
    limit: number,
    offset: number,
    filter: string,
    searchText: string,
    page: string,
  ) {
    return new Promise(async (resolve, reject) => {
      Promise.all([this.getAllSearchProducts(limit, offset, filter, searchText, page)]).then(
        (result) => {
          const searchProducts = result[0];
          resolve(searchProducts);
        },
        reject,
      );
    });
  }

  getAllSearchProducts(
    limit: number,
    offset: number,
    filter: string,
    searchText: string,
    page: string,
  ): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `/api/salespro/products/${filter}/${limit}/${offset}`;
        if (searchText.length > 0) {
          url = `/api/salespro/products/${filter}/${searchText}/${limit}/${offset}`;
        }
        if (page === 'builder' && (filter || searchText)) {
          url = `/api/salespro/products/filterby/${page}/${filter}/${limit}/${offset}`;
          if (searchText) {
            url = `/api/salespro/products/filterby/${page}/${filter}/${searchText}/${limit}/${offset}`;
          }
        }
        this.filter = filter;
        this.offset = offset;
        this.page = page;
        this._http.get(url).subscribe((response: any) => {
          const products = response && response.rows ? response.rows : [];
          resolve(products);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
  /*------------------SEARCH BOX MODIFCATIONS END******************************/

  // Remove the Selected Product Info
  removeProduct(selectedProduct: Product): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .delete(`/api/salespro/products/${selectedProduct.guid}`)
        .subscribe((response: any) => {
          const productIndex = this._products['rows'].indexOf(selectedProduct);
          const allProductIndex = this._allProducts.indexOf(selectedProduct);
          this._allProducts.splice(allProductIndex, 1);
          this._products['rows'].splice(productIndex, 1);
          this.onProductPagination.next(this._products);
          resolve(response);
        }, reject);
    });
  }

  // Update the Selected Product Info
  updateProduct(oldProduct: Product, newProduct: any, isBuilder = false): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .patch(`/api/salespro/products/${oldProduct.guid}`, newProduct)
        .subscribe((response: any) => {
          const productIndex = this._products['rows'].indexOf(oldProduct);
          if (!isBuilder) {
            /*     oldProduct.update(newProduct);
          this._products[productIndex].update(oldProduct);*/
            this._products['rows'][productIndex] = newProduct;
          } else {
            this._products['rows'][productIndex]['builders'] = newProduct.builders;
          }
          this.onProductPagination.next(this._products);
          resolve(response);
        }, reject);
    });
  }

  // Preparing Tagged products
  prepareTaggedProducts(selectedPackage: any): Product[] {
    if (this._allProducts.length > 0) {
      return this._allProducts.filter((product) => {
        const packageById = [];
        let packageFilter = true;
        if (selectedPackage) {
          let packages = [];
          let productPackage = [];
          try {
            packages = JSON.parse(selectedPackage);
            productPackage = JSON.parse(product.packages);
          } catch (error) {
            packages = [];
            productPackage = [];
          }
          const packagesDiff = _.differenceBy(packages, productPackage, 'guid');
          if (packagesDiff && packagesDiff.length !== 0) {
            packageFilter = packagesDiff.length !== packages.length;
          }
        }

        return packageFilter;
      });
    }
  }

  getProductsByCategory(categoryGuid): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/products/${categoryGuid}`).subscribe((response: any) => {
        this._products = response;
        this._products = this._products.map((item) => {
          return new Product(item);
        });
        resolve(this._products);
      });
    });
  }
  // Upload Spec Sheet
  uploadSpecSheet(name: string, productSpecSheet: File) {
    return new Promise((resolve, reject) => {
      const formData: any = new FormData();

      formData.append('name', name);
      formData.append('pdf', productSpecSheet);
      return this._http
        .post(`/api/salespro/products/uploadProductSpecSheet`, formData)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
    /*return this._http.post(`/api/salespro/products/uploadProductSpecSheet`, formData, {
      reportProgress: true,
      observe: 'events',
    });*/
  }
}
