import { Injectable } from '@angular/core';
import { Branch } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { OdooRpcService, ɵa } from '@cogito/angular-odoo';

@Injectable()
export class BranchService  {
  onBranchChanged: BehaviorSubject<any>;

  private _branches: Branch[];

  constructor(private _http: HttpClient, private _odoo: OdooRpcService) {
    // Set the defaults
    this._branches = [];
    this.onBranchChanged = new BehaviorSubject(this._branches);
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getAllBranches()]).then((result) => {
        resolve(this._branches);
      }, reject);
    });
  }

  async getAllOdooBranches() {
    return new Promise(async (resolve, reject) => {
      try {
        const branches = this._odoo.call('branch', 'search_read', [], {}).toPromise();

        resolve(branches);
      } catch (error) {
        return reject(error);
      }
    });
  }

  // Add New Branch Info
  addNewBranch(newBranch: Branch): Promise<any> {
    return new Promise((resolve, reject) => {
      return this._http.post(`/api/salespro/branches`, newBranch).subscribe((response: any) => {
        this._branches.push(response);
        this.onBranchChanged.next(this._branches);
        resolve(response);
      }, reject);
    });
  }

  // Get Branch Info by GUID
  getBranchByGuid(guid: string): Promise<Branch> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/branches/${guid}`).subscribe((response: any) => {
        const branch: Branch = new Branch(response);
        return resolve(branch);
      }, reject);
    });
  }

  // Get All Branch Info
  getAllBranches(): Promise<Branch[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/branches`).subscribe((response: any) => {
        this._branches = response;

        this._branches = this._branches.map((item) => {
          return new Branch(item);
        });

        this.onBranchChanged.next(this._branches);
        resolve(this._branches);
      }, reject);
    });
  }

  // Remove the Selected Branch Info
  removeBranch(selectedBranch: Branch): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .delete(`/api/salespro/branches/${selectedBranch.guid}`)
        .subscribe((response: any) => {
          const branchIndex = this._branches.indexOf(selectedBranch);
          this._branches.splice(branchIndex, 1);
          this.onBranchChanged.next(this._branches);
          resolve(response);
        }, reject);
    });
  }

  // Update the Selected Branch Info
  updateBranch(oldBranch: Branch, newBranch: Branch): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .patch(`/api/salespro/branches/${oldBranch.guid}`, newBranch)
        .subscribe((response: any) => {
          const branchIndex = this._branches.indexOf(oldBranch);
          // oldBranch.update(newBranch);
          // this._branches[branchIndex].update(oldBranch);
          this._branches[branchIndex] = newBranch;
          this.onBranchChanged.next(this._branches);
          resolve(response);
        }, reject);
    });
  }
}
