import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ProductCategory } from 'app/shared/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class PaymentLogService {
  constructor(private _http: HttpClient) {}

  // Update Payment Log
  logPaymentDetails(updatedContent: any, remote = '') {
    return new Promise((resolve, reject) => {
      let sUrl = `/api/salespro/paymentLog`;
      if (remote === 'remote') {
        sUrl = `/api/salespro/paymentLog/remote`;
      }

      return this._http.post(sUrl, updatedContent).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
  updateCustomer(customerGuid: string, updatedContent: any, remote = ''): Promise<any> {
    console.log('customer update...........', updatedContent);
    let sUrl = `/api/salespro/customers/${customerGuid}`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/customers/remote/${customerGuid}`;
    }
    return this._http.patch(sUrl, updatedContent).toPromise();
  }
}
