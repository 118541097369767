// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 0 0 auto;
  z-index: 3;
}
:host .mat-toolbar {
  background: inherit;
  color: inherit;
  box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px -1px 3px 0px rgba(0, 0, 0, 0.12);
}
:host.above {
  position: relative;
  z-index: 99;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvb3Rlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSxjQUFBO0VBQ0EsVUFBQTtBQUNGO0FBQ0U7RUFDRSxtQkFBQTtFQUNBLGNBQUE7RUFDQSwySEFBQTtBQUNKO0FBR0U7RUFDRSxrQkFBQTtFQUNBLFdBQUE7QUFESiIsImZpbGUiOiJmb290ZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXg6IDAgMCBhdXRvO1xuICB6LWluZGV4OiAzO1xuXG4gIC5tYXQtdG9vbGJhciB7XG4gICAgYmFja2dyb3VuZDogaW5oZXJpdDtcbiAgICBjb2xvcjogaW5oZXJpdDtcbiAgICBib3gtc2hhZG93OiAwcHggLTFweCAxcHggLTFweCByZ2JhKDAsIDAsIDAsIDAuMiksXG4gICAgICAwcHggMHB4IDFweCAwcHggcmdiYSgwLCAwLCAwLCAwLjE0KSwgMHB4IC0xcHggM3B4IDBweCByZ2JhKDAsIDAsIDAsIDAuMTIpO1xuICB9XG5cbiAgJi5hYm92ZSB7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICAgIHotaW5kZXg6IDk5O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/layout/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,UAAA;AACF;AACE;EACE,mBAAA;EACA,cAAA;EACA,2HAAA;AACJ;AAGE;EACE,kBAAA;EACA,WAAA;AADJ;AACA,ozBAAozB","sourcesContent":[":host {\n  display: flex;\n  flex: 0 0 auto;\n  z-index: 3;\n\n  .mat-toolbar {\n    background: inherit;\n    color: inherit;\n    box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2),\n      0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px -1px 3px 0px rgba(0, 0, 0, 0.12);\n  }\n\n  &.above {\n    position: relative;\n    z-index: 99;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
