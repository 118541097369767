export class Log {
  id?: number;
  guid?: string;
  input_params?: string;
  output_response?: string;
  page_type?: string;
  street?: string;
  description?: string;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.input_params !== undefined) {
      this.input_params = item.input_params;
    }
    if (item.output_response !== undefined) {
      this.output_response = item.output_response;
    }
    if (item.page_type !== undefined) {
      this.page_type = item.page_type;
    }
    if (item.street !== undefined) {
      this.street = item.street;
    }

    if (item.description !== undefined) {
      this.description = item.description;
    }
  }
}
