// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `navbar.horizontal-style-1 {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN0eWxlLTEuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSUU7RUFDRSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxjQUFBO0VBQ0EsV0FBQTtFQUNBLFlBQUE7RUFDQSxnQkFBQTtFQUNBLGdCQUFBO0FBSEoiLCJmaWxlIjoic3R5bGUtMS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIm5hdmJhci1ob3Jpem9udGFsLXN0eWxlLTEge1xufVxuXG5uYXZiYXIge1xuICAmLmhvcml6b250YWwtc3R5bGUtMSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGZsZXg6IDEgMSBhdXRvO1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogNTZweDtcbiAgICBtYXgtaGVpZ2h0OiA1NnB4O1xuICAgIG1pbi1oZWlnaHQ6IDU2cHg7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layout/components/navbar/horizontal/style-1/style-1.component.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AAHJ;AACA,ooBAAooB","sourcesContent":["navbar-horizontal-style-1 {\n}\n\nnavbar {\n  &.horizontal-style-1 {\n    display: flex;\n    flex-direction: column;\n    flex: 1 1 auto;\n    width: 100%;\n    height: 56px;\n    max-height: 56px;\n    min-height: 56px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
