import { crmTagConfig } from './crm.tag';

export const restartStages = [
  crmTagConfig.status.PLAN_SELECTED,
  crmTagConfig.status.PRODUCT_SELECTED,
  crmTagConfig.status.REMOTE_FINANCE_FAILED,
  crmTagConfig.status.FINANCE_FAILED,
  crmTagConfig.status.PRODUCT_REVIEWED,
  crmTagConfig.status.PAYMENT_REVIEWED,
  crmTagConfig.status.PAYMENT_SKIPPED,
  crmTagConfig.status.REMOTE_PRODUCT_REVIEWED,
  crmTagConfig.status.DOCUMENT_SIGNED_SALESPERSON,
  crmTagConfig.status.ACI_PAYMENT_DONE,
  crmTagConfig.status.FINANCE_APPLICATION_PROCESSING,
  crmTagConfig.status.FINANCE_APPLICATION_SUBMITTED,
  crmTagConfig.status.FINANCE_PENDING_CUSTOMER_ACCOUNT,
  crmTagConfig.status.FINANCE_PENDING_FINAL_AMOUNT,
  crmTagConfig.status.FINANCE_PENDING_CUSTOMER_SIGNATURE,
  crmTagConfig.status.FINANCE_ACCEPTED,
  crmTagConfig.status.FINANCE_SUBMITTED,
  crmTagConfig.status.FINANCE_MANUAL_REVIEW,
  /*crmTagConfig.status.REMOTE_ACI_PAYMENT_DONE,*/
  /*crmTagConfig.status.CUSTOMER_INFO_ADDED,*/
];
