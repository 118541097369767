export class Package {
  id?: number;
  guid?: string;
  name?: string;
  rmr?: number;
  description?: string;
  labor?: number;
  rmrIsAddOn?: boolean;
  basePrice?: number;
  listPrice?: number;
  createdAt?: string;
  updatedAt?: string;
  products?: number;
  rmrIsPreSelcted?: boolean;
  groupInfo?: string;
  odooId?: string;
  oneYearTerm?: boolean;
  zeroSelfgen?: boolean;
  maxValue: number;
  minValue: number;
  isCameraAddOn:boolean;
  isDoorbellAddOn: boolean;
  leadInfo:string;
  filters?: any;

  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.name !== undefined) {
      this.name = item.name;
    }
    if (item.rmr !== undefined) {
      this.rmr = item.rmr;
    }
    if (item.description !== undefined) {
      this.description = item.description;
    }
    if (item.labor !== undefined) {
      this.labor = item.labor;
    }
    if (item.rmrIsAddOn !== undefined) {
      this.rmrIsAddOn = item.rmrIsAddOn;
    }
    if (item.basePrice !== undefined) {
      this.basePrice = item.basePrice;
    }
    if (item.listPrice !== undefined) {
      this.listPrice = item.listPrice;
    }
    if (item.createdAt !== undefined) {
      this.createdAt = item.createdAt;
    }
    if (item.updatedAt !== undefined) {
      this.updatedAt = item.updatedAt;
    }
    if (item.products !== undefined) {
      this.products = item.products;
    }
    if (item.rmrIsPreSelcted !== undefined) {
      this.rmrIsPreSelcted = item.rmrIsPreSelcted;
    }
    if (item.groupInfo !== undefined) {
      this.groupInfo = item.groupInfo;
    }
    if (item.odooId !== undefined) {
      this.odooId = item.odooId;
    }
    if (item.oneYearTerm !== undefined) {
      this.oneYearTerm = item.oneYearTerm;
    }
    if (item.zeroSelfgen !== undefined) {
      this.zeroSelfgen = item.zeroSelfgen;
    }
    if (item.minValue !== undefined) {
      this.minValue = item.minValue;
    }
    if (item.maxValue !== undefined) {
      this.maxValue = item.maxValue;
    }
    if (item.isCameraAddOn !== undefined) {
      this.isCameraAddOn = item.isCameraAddOn;
    }
    if (item.isDoorbellAddOn !== undefined) {
      this.isDoorbellAddOn = item.isDoorbellAddOn;
    }
    if (item.leadInfo !== undefined) {
      this.leadInfo = item.leadInfo;
    }
    if (item.filters !== undefined) {
      this.filters = item.filters;
    }
  }
}
  