import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OdooRpcService } from '@cogito/angular-odoo';
import { float } from 'aws-sdk/clients/lightsail';

@Injectable()
export class CrmStageService {
  constructor(
    private _http: HttpClient,
    private _odoo: OdooRpcService
  ) {
  }

  // Add new Crm Stage Info
  addNewCrmStage(name: string, probability: float, team: number): Promise<number> {
    return new Promise((resolve, reject) => {
      this._odoo.call('crm.stage', 'create', [{
        name,
        probability,
        team_id: team
      }], {}).subscribe(async (newStageId: number) => {
        resolve(newStageId);
      }, reject);
    });
  }

  // Get Crm Stage Info
  getCrmStage(name: string, team: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._odoo.call('crm.stage', 'search_read', [
        [
          '&',
          [ 'name', '=', name ],
          [ 'team_id', '=', team ]
        ]
      ], {
        limit: 1
      }).subscribe((response: any) => {
        if (response && response.length > 0) {
          resolve(response[0]);
        } else {
          resolve(null);
        }
      }, reject);
    });
  }

  // Update Crm Stage Info
  updateCrmStage(stageId: number, name: string) {
    return new Promise((resolve, reject) => {
      this._odoo.call('crm.stage', 'write', [ [ stageId ], {
        name
      }], {}).subscribe((updated: any) => {
        resolve(updated);
      }, reject);
    });
  }
}
