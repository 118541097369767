import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { NgModule, ErrorHandler } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { environment } from 'environments/environment';

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}
Bugsnag.start({
  apiKey: '9456fd2da194ee1575e8e2804af1ee27',
  releaseStage: environment.stage,
  enabledReleaseStages: ['test', 'develop'],
  appVersion: '1.2.2',
});

@NgModule({
  providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }],
})
export class ErrorModule {}
