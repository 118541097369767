import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import Bugsnag from '@bugsnag/js';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const prefixName = request.url.split('/')[1];
        const apiName = request.url.split('/')[2];
        Bugsnag.leaveBreadcrumb('Api Request Error', err);
        if (prefixName === 'api') {
          switch (apiName) {
            case 'salespro':
              if (err.status === 401) {
                this._auth.logout();
                Bugsnag.notify(err);
              }
              break;
            default:
              break;
          }
        }

        return throwError(err);
      }),
    );
  }
}
