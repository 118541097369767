import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CacheRefreshModalComponent } from 'app/shared/components/cache-refresh-modal/cache-refresh-modal.component';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(public matDialog: MatDialog,) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            console.log('ok');
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 500 && error.error && error.error.backendVersion) {
              const backendVersion = error.error.backendVersion;
              const a = this.matDialog.open(CacheRefreshModalComponent, {
                disableClose: true,
                data : {
                  version : backendVersion
                }
              });
            }
          }
        }
      )
    );
  }
}
